
.LeagueTeamTable {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__title {
    margin-bottom: 24px;

    &--center {
      text-align: center;
    }
  }

  &__content {
    overflow-x: auto;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--color-grey);
    border-bottom: none;
  }

  &__row {

    &--lastAdvancing {
      border-bottom: 2px solid var(--color-yellow);
    }

    &--firstDescending {
      border-top: 2px solid var(--color-red);
    }

    &--champion,
    &--champion:nth-child(2n + 1) {
      border: 2px solid var(--color-yellow);
      background: var(--color-yellow-overlay-08);
      margin: 0 -1px;
    }

    &--green {
      background: #dcfce7;
    }

    &--yellow {
      background: #fefce8;
    }

    &--orange {
      background: #fff7ed;
    }

    &--red {
      background: #fef2f2;
    }

    &--gray {
      background: #f9fafb;
    }
  }

  &__cell {
    padding: 16px;
    font-size: 16px;
    color: var(--color-text-primary);
    border-bottom: 1px solid var(--color-grey);
    text-align: center;

    @media (max-width: $break-small) {
      padding: 8px;
    }

    &--left {
      text-align: left;
    }

    &--header {
      background: var(--color-black-04);
      color: var(--color-text-secondary);
      font-size: 12px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &--faded {
      color: var(--color-grey-20);
    }

    &--order {
      font-size: 16px;
      line-height: 24px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-text-secondary);
      width: 24px;
    }
  }

  &__team {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 14px;
    color: var(--color-blue-20);
    line-height: 24px;

    @media (max-width: $break-small) {
      gap: 8px;
      font-size: 12px;
    }
  }

  tbody tr:hover &__cell {
    background: var(--color-blue-overlay-04);
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 4px;

    &List {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding: 8px 0;
    }

    &Color {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 10px;
      border: 1px solid;

      span {
        display: none;
      }

      &--champion {
        background: #eab308;
        border-color: #eab308;
      }

      &--green {
        background: #dcfce7;
        border-color: #22c55e;
      }

      &--yellow {
        background: #fefce8;
        border-color: #eab308;
      }

      &--orange {
        background: #fff7ed;
        border-color: #f97316;
      }

      &--red {
        background: #fef2f2;
        border-color: #ef4444;
      }

      &--gray {
        background: #f9fafb;
        border-color: #6b7280;
      }
    }

    &Description {
      color: var(--color-text-secondary);
      font-size: 12px;
    }
  }
}
