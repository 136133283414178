
.TeamTable {
  margin: 24px 0;
  overflow-x: auto;

  &__title {
    margin: 40px 0 0;
  }

  .LeagueTeamTable {
    margin: 0;
  }

  &__retributions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px 18px;
    background: var(--color-blue-overlay-04);
    font-size: 12px;
    color: var(--color-text-secondary);

    @media (max-width: $break-small) {
      padding: 8px;
    }

    p {
      display: flex;
      align-items: flex-start;
      line-height: 1.24;
      gap: 6px;
    }
  }

  &__update {
    font-size: 12px;
    color: var(--color-text-secondary);
  }
}
