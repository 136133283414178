

.StaticPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 24px;
  gap: 24px;

  @media (max-width: $break-small) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    margin: 0;
  }

  @media (max-width: $break-small) {
    a {
      width: 100%;
    }
  }

}
