
.TeamContact {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 24px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &__description {
    margin: 8px 0;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text-secondary);
    white-space: pre-line;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    margin: 24px 0;

    @media (max-width: $break-small) {
      gap: 16px;
      margin: 16px 0;
    }
  }

  &__content {
    width: 100%;
  }

  &__image {
    width: 100%;
    flex-grow: 1;

    .Content__image {
      margin: 0;
    }
  }

  &__person {
    flex-grow: 1;

    &List {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;
      margin: 8px 0;
      width: 100%;
    }

    strong {
      display: block;
      font-size: 16px;
      color: var(--color-text-primary);
    }

    small {
      font-size: 14px;
      color: var(--color-text-secondary);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    width: 100%;
    margin: 24px 0;

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      margin: 16px 0;
    }

    & > div {
      flex-grow: 1;
    }
  }

  &__ground {
    margin: 8px 0;

    iframe {
      margin-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
    }

    strong {
      font-size: 16px;
      color: var(--color-text-primary)
    }

    address {
      font-style: normal;
      font-size: 14px;
      color: var(--color-text-secondary);
    }
  }

  &__site {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: var(--color-blue);
    margin: 8px 0;
    font-weight: bold;
  }
}
