
.GroundHeader,
.OffenseHeader {
  margin: 80px 0;
  position: relative;

  @media (max-width: $break-normal) {
    margin: 64px 0;
  }

  @media (max-width: $break-small) {
    margin: 40px 0 64px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-blue-20);
    z-index: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin: 24px 0;

    @media (max-width: $break-normal) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @media (max-width: $break-small) {

      a {
        width: 100%;
      }
    }
  }

  &__map {
    position: relative;
    width: 100%;
    height: 360px;
    border-radius: 16px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    background: var(--color-white);
    z-index: -1;

    @media (max-width: $break-small) {
      height: 220px;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__title {
    margin: 0;
    color: var(--color-white);

    @media (max-width: $break-small) {
      &:before {
        display: none;
      }
    }
  }

  &__cta {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;

    &Helper {
      display: none;
      margin-bottom: 16px;

      @media (max-width: $break-small) {
        display: block;
      }
    }
  }

  &__button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    appearance: none;
    border: none;
    font-weight: bold;
    background: var(--color-blue-20);
    color: var(--color-white);
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 8px;
    height: 40px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    transition: transform ease-in $animation-speed, box-shadow ease-in $animation-speed;
    will-change: transform, box-shadow;
    white-space: nowrap;

    &:active,
    &:active:focus {
      transform: scale3d(0.99, 0.99, 1);
    }
  }
}
