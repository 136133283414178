
.Loader {
  display: block;
  width: 32px;
  height: 32px;
  background: url("../../../assets/icon-cross-disabled.svg") no-repeat center;
  background-size: 24px 24px;
  opacity: .4;
  animation: loader-animation ease-in-out 3s forwards infinite;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    min-height: 50vh;

    &--small {
      min-height: 25vh;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    font-family: $font-primary;
    color: var(--color-text-secondary);
    user-select: none;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(360deg) scale(0.9);
    opacity: .4;
  }
  33% {
    transform: rotate(-360deg) scale(1);
    opacity: 1;
  }
  66% {
    transform: rotate(-360deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: .4;
  }
}
