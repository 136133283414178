

h1,
.h1 {
  color: #2f2f2f;

  &:before {
    background: url("~@amf/shared/assets/icon-cross-black.svg") no-repeat center;
    background-size: 24px 24px;
  }
}

h2, h3 {
  color: #2f2f2f;
}
