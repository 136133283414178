

.MatchHeaderAlt {
  background: rgba(51, 51, 51, 1);

  &__layout:after {
    background: var(--color-white);
  }
}

.MatchJumbotron {

  &__box {
    background: rgba(51, 51, 51, 1);
  }

  &__status {

    &Inner {
      background: rgba(33, 33, 33, 1);
    }
  }
}
