
.Navigation {

  .Navigation--blue .Navigation__main {

  }

  &__brand {
    width: 56px;
    min-width: 56px;
    position: relative;

    @media (max-width: $break-small) {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }
  }

  &__menuItem--selected:after {
    background-image: url("~@amf/shared/assets/icon-cross-black.svg") !important;
  }

  &--white &__menuItem:before {
    background-image: url("~@amf/shared/assets/icon-menu-arrow-black.svg") !important;
  }

  &--white .Navigation__main {
    color: var(--color-black);
  }

  &--white .Navigation__submenu {
    background: rgba(47, 47, 47, .98);
    box-shadow: none;
  }

  &--dropdown .Navigation__submenu {
    background: none;
  }

  &--white &__submenuItem {
    color: var(--color-white);
  }

  &--white &__menuItem {
    color: var(--color-black);
  }

  &--white &__menuItem--selected {
    color: var(--color-black);
  }

  &--dropdown .Navigation__submenuItem {
    color: var(--color-black) !important;
  }
}
