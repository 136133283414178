
.MatchPlayOffTable {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
}

.MatchPlayOff {
  position: relative;
  background: var(--color-blue-10);
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
  border-radius: 8px;
  overflow: hidden;

  &--loader {
    min-height: 420px;
    @include shimmer(3s, 1024px, var(--color-grey), var(--color-grey-10), padding-box);
    box-shadow: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 24px;
    gap: 48px;

    &:before,
    &:after {
      display: none;
    }
  }

  &--loader &__loader {
    background: var(--color-white-40);
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  --playoff-player-height: 32px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 24px;
    height: 100%;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(5, 11, 109, 1) 0%, rgba(5, 11, 109, 0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(5, 11, 109, 0) 0%, rgba(5, 11, 109, 1) 100%);
  }

  &__outer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
      text-align: center;
      margin: 0;
    }
  }

  &__content {
    width: 100%;
    overflow: auto;
    z-index: 1;
    position: relative;
  }

  .reacket {
    padding: 24px 24px 80px;
    width: max-content;
    min-width: 100%;
    position: relative;
  }

  .reacket-match {
    margin: 16px 0;
  }

  .reacket-player {
    background-color: var(--color-white-16);
    border: none;
    padding: 0;
    height: var(--playoff-player-height);
    transition: background-color 0.1s ease-out;
    cursor: default;

    .reacket-player-seed {
      display: none;
    }

    .reacket-player-name {
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 14px;
      color: var(--color-white);
      height: 100%;
      line-height: var(--playoff-player-height);
      padding: 0 12px;
      transition: background-color 0.2s ease-out;

      &.player-winner {
        background: var(--color-green-10);
        color: var(--color-blue-20);
      }
    }

    .reacket-player-score {
      padding: 0;
      width: 40px;
      height: 100%;
      line-height: var(--playoff-player-height);
      background: var(--color-yellow-native);
      color: var(--color-blue-20);
      text-align: center;
      font-family: $font-primary;
      font-size: 14px;
      transition: background-color 0.2s ease-out;
      border-left: 1px solid var(--color-black-16);
      cursor: pointer;

      &.inactive {
        background: var(--color-white-16);
      }

      &.player-winner {
        background: var(--color-green-10);
        color: var(--color-blue-20);
      }
    }

    &.reacket-highlighted,
    &.reacket-highlighted.reacket-winner {
      background-color: var(--color-yellow-native);
      border: none;

      .reacket-player-name,
      .reacket-player-score {
        color: var(--color-blue-20);
        background-color: var(--color-yellow-native);

        &.player-winner {
          background: var(--color-green-10);
        }
      }
    }

    &:first-child {
      // border-bottom: 1px solid var(--color-white-08);
      border-bottom: none;
    }
  }

  .reacket-round-headers {
    gap: 48px;
    justify-content: center;

    .reacket-round-header {
      text-align: center;
      width: 100%;
      margin: 0;
      min-width: 240px;
      max-width: 320px;
      text-transform: uppercase;
      font-size: 14px;
      color: var(--color-white-40);
    }
  }

  .reacket-match {

    .reacket-match-id {
      display: none;
    }

    .reacket-players {
      width: 100%;
      min-width: 240px;
      max-width: 320px;
    }

    &.match-placeholder {
      height: 64px;

      .reacket-players {
        display: flex;
        align-items: center;
      }

      .reacket-player:first-child {
        width: 100%;

        .reacket-player-name {
          display: flex;
          align-items: center;
        }
      }

      .reacket-player:last-child {
        // opacity: .24;
        display: none;
      }

      .reacket-player-score {
        display: none;
      }
    }
  }

  .reacket-connector {
    width: 24px;

    .reacket-horizontal-line {
      border-color: var(--color-white-16);
    }

    .reacket-vertical-line {
      border-color: var(--color-white-16);
    }
  }

  .reacket-winner .reacket-player-name {
    color: var(--color-white);
  }

  .reacket-winner .reacket-player-score {
    color: var(--color-blue-20);
    font-weight: normal;
  }

  .reacket-rounds {
    width: 100%;
    position: relative;
    min-height: 64px;
    justify-content: center;
  }

  .reacket-round {
    width: 100%;
    max-width: 320px;
    position: relative;
  }

  .reacket-round:last-child {

    .reacket-match:last-child {
      position: absolute;
      bottom: -40px;
      right: 0;
      width: 100%;
    }

    .reacket-match:last-child:first-child {
      position: static;
    }
  }
}
