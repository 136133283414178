
.TeamMatches {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  .MatchTable {
    margin: 40px 0;

    @media (max-width: $break-small) {
      margin: 16px 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: $break-small) {
      width: 100%;
      flex-direction: column;
      gap: 8px;

      .FormField {
        width: 100%;
      }
    }

    .FormField {
      margin: 0;
    }
  }

  h2 {
    margin: 0;
  }

  &__pagination {
    margin: 24px 0;
    text-align: center
  }

  &__field {
    margin: 0;

    @media (max-width: $break-small) {
      width: 100%;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }
}
