

.TeamList {

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0;
  }

  &__item {
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--color-grey);
    color: var(--color-text-primary);

    &:hover {
      background: var(--color-blue-overlay-04);
    }

    &Content {
      display: flex;
      flex-direction: column;
    }

    &Title {
      margin: 0;
      font-size: 14px;
    }

    &Description {
      font-size: 14px;
      line-height: 16px;

      @media (max-width: $break-small) {
        display: none;
      }
    }

    &Type {
      font-size: 14px;
      line-height: 14px;
      color: var(--color-text-secondary);
      text-align: right;
    }
  }

  &__dress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: var(--color-blue-20);
    border-radius: 50%;

    svg {
      display: block;
      width: 80%;
      height: 80%;
    }
  }
}
