
.Error {
  background: var(--color-yellow);

  &__image svg polygon {
    fill: var(--color-text-primary);
  }

  &__image svg path {
    stroke: var(--color-text-primary);
  }

  &__title,
  &__message {
    color: var(--color-text-primary);
  }
}