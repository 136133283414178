

.MatchBrackets {
  --match-height: 64px;
  --match-x-spacing: 12px;
  --match-y-spacing: 16px;
  --color-bracket: var(--color-yellow);
  --color-round: var(--color-grey-10);

  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
    --match-height: 56px;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: $break-small) {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // min-height: 60vh;

    @media (max-width: $break-small) {
      overflow-x: auto;
    }
  }

  &__container {
    width: max-content;

    @media (max-width: $break-small) {

    }
  }

  &__round {
    flex-grow: 1;
    display: grid;
    border-bottom: 1px solid var(--color-round);
  }

  &__heading {
    text-align: center;
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
  }

  &__bracket {
    display: flex;
    margin: 0;
    padding: 80px 0;
    flex-direction: column;
    justify-content: space-around;
    border-right: 1px dashed var(--color-round);
    flex: 1;
    gap: var(--match-y-spacing);
    position: relative;

    &:first-child {
      border-left: 1px dashed var(--color-round);
    }
  }

  &__match {
    display: flex;
    margin: 0 var(--match-x-spacing);
    position: relative;
    height: var(--match-height);

    &:after {
      content:'';
      border-color: var(--color-bracket);
      border-width: 2px;
      position: absolute;
      display: block;
      width: var(--match-x-spacing);
      right: calc(-1px - var(--match-x-spacing));
    }

    &:nth-of-type(odd):after {
      border-right-style: solid;
      border-top-style: solid;
      top: 50%;
    }

    &:nth-of-type(even):after {
      border-right-style: solid;
      border-bottom-style: solid;
      bottom: 50%;
    }

    &:before {
      content:'';
      border-top: 2px solid var(--color-bracket);
      position: absolute;
      height: 2px;
      width: var(--match-x-spacing);
      left: calc(var(--match-x-spacing) * -1);
      top: 50%;
    }

    &--outOfGrid {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;

      &:before {
        display: none;
      }
    }

    &Content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      transition: box-shadow ease-in-out $animation-speed;
      min-width: 320px;

      &:hover {
        box-shadow: 0 2px 16px 0 var(--color-blue-overlay-16);
      }
    }

    &Teams {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0 12px;
      flex-direction: column;
      line-height: 20px;
      text-transform: uppercase;
      color: var(--color-white);
      background: var(--color-blue-20);
      font-family: $font-primary;
      font-size: 12px;

      strong {
        font-size: 14px;
        font-weight: 400;
      }
    }

    &Score {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $font-primary;
      color: var(--color-blue-20);
      background: var(--color-yellow);
      height: 100%;
      padding: 16px;
    }
  }

  @for $i from 1 through 16 {
    &__bracket[data-round="#{$i}"] &__match:nth-of-type(odd):after {
      height: calc(var(--match-height) * #{$i}); // 100, 200, 350, 700
    }

    &__bracket[data-round="#{$i}"] &__match:nth-of-type(even):after {
      height: calc(var(--match-height) * #{$i}); // 100, 200, 350, 700
      // top: calc(50% * -#{$i}); // -50, -150, -300, -650
    }
  }

  &__bracket:first-of-type &__match:before,
  &__bracket:last-of-type &__match:after {
    display: none;
  }
}
