
.PlayerListItem {
  --image-size: 48px;

  display: grid;
  grid-template-columns: var(--image-size) 1fr 40px;
  gap: 16px;
  cursor: pointer;
  margin: -8px;
  padding: 8px;
  border-radius: 32px;

  &:hover {
    background: var(--color-grey);
  }

  &__photo {
    width: var(--image-size);
    height: var(--image-size);

    img,
    div {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      border-radius: 50%;
      background: var(--color-text-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-text-secondary);
      font-weight: 900;
      user-select: none;
      transition: box-shadow ease-in-out $animation-speed;
      will-change: box-shadow;
    }
  }

  &:hover &__photo img,
  &:hover &__photo div {
    box-shadow: 5.4px 5.2px 15px 0 rgb(0 0 0 / 21%);
  }

  &__name {
    margin: 0;
    color: var(--color-blue-20);
    line-height: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }

  &__about {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      color: var(--color-text-primary);
      text-transform: uppercase;
      border-left: 1px solid var(--color-text-secondary);
      padding-left: 8px;

      &:first-child {
        padding-left: 0;
        border-left: none;
      }
    }

  }

  &__chevron {
    opacity: 0;
    display: flex;
    align-items: center;
    padding-right: 16px;

    svg {
      width: 24px;
      height: 24px;

      * {
        fill: var(--color-grey-20);
      }
    }
  }

  &__flag {
    object-fit: cover;
    object-position: center;
    height: 12px !important;
    border: 1px solid var(--color-grey);
  }

  &:hover &__chevron {
    opacity: 1;
  }
}