
@mixin noscrollbar() {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: inherit;
    width: 0;
    height: 0;
    border: none;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover::-webkit-scrollbar-thumb {
    height: 0;
    background-color: transparent;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: transparent;
    border: none;
  }
}

@mixin scrollbar($width: 4px, $height: auto, $color: black, $darkenColor: black) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    background: inherit;
    width: $width;
    height: $height;
    border: none;
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover::-webkit-scrollbar-thumb {
    height: $height;
    background-color: $color;
    border: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $darkenColor;
    border: none;
  }
}

@mixin dark-theme {
  @media (prefers-color-scheme: dark) {
    @content
  }
}

@mixin shimmer($speed: 1.5s, $size: 1024px, $background: var(--color-grey), $shine: var(--color-grey-10), $clipping: content-box) {
  background: $background linear-gradient(90deg, $background 0%, $shine 20%, $background 40%, $background 100%) no-repeat;
  background-size: $size $size;
  animation: linear $speed infinite shimmer forwards;
  background-clip: $clipping;
  color: transparent;
  text-shadow: none;
  box-decoration-break: clone;
  cursor: default;
}

@keyframes shimmer {
  0% {
    background-position: -1024px 0;
  }

  100% {
    background-position: 1024px 0;
  }
}
