

.TeamStats {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $break-normal) {
    margin: 40px 0;
  }

  &__title {
    margin: 0;

    &--center {
      text-align: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .FormField {
      margin: 0;

      @media (max-width: $break-small) {
        width: 100%;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;

    @media (max-width: $break-small) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-grey);
  }

  &__segment {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-grey);
    justify-content: space-between;
    padding: 8px 16px;

    &:last-child {
      border-bottom: none;
    }

    &Value {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-family: $font-primary;
      font-weight: bold;
      color: var(--color-text-primary);

      span {
        font-size: 12px;
        color: var(--color-text-secondary);
      }
    }

    &Title {
      font-size: 12px;
      color: var(--color-text-secondary);
      font-weight: bold;
    }

    &Subtitle {
      font-size: 12px;
    }
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $break-small) {
      a {
        width: 100%;
      }
    }
  }
}
