

.PlayerUnions {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background: var(--color-blue-overlay-04);
    padding: 24px;
    border-radius: 8px;

    h3 {
      margin: 0;
    }
  }

  &__logo {
    display: block;
    width: 64px;
    height: 64px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    text-align: center;

    p {
      color: var(--color-text-secondary);
      font-size: 14px;
    }
  }
}
