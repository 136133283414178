

.MatchEvent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 50%;
  position: relative;
  z-index: 1;
  gap: 24px;

  @media (max-width: $break-medium) {
    align-self: flex-end;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }

  &--right {
    align-self: flex-end;
    justify-content: flex-start;
    flex-direction: row;
  }

  &__minute {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: var(--color-grey-10);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    margin-right: -20px;
    position: relative;

    @media (max-width: $break-medium) {
      margin-right: 0;
      margin-left: -10px;
    }

    @media (max-width: $break-small) {
      margin-left: -2px;
    }
  }

  &--right &__minute {
    margin-right: 0;
    margin-left: -20px;

    @media (max-width: $break-medium) {
      margin-left: -10px;
    }

    @media (max-width: $break-small) {
      margin-left: -2px;
    }
  }

  &--goal &__minute {
    background: var(--color-green);
    color: var(--color-black);
  }

  &--penalty &__minute,
  &--red &__minute {
    background: var(--color-red);
    color: var(--color-white);
  }

  &--yellow &__minute {
    background: var(--color-yellow-native);
    color: var(--color-black);
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    background: var(--color-white);
    border-radius: 4px;
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    padding: 16px;
    min-width: 320px;

    @media (max-width: $break-medium) {
      width: 100%;
      padding: 8px;
      min-width: 0;
    }
  }

  &__title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    span {
      display: block;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;
      padding-top: 3px;
    }
  }

  &__message {
    font-size: 14px;
    color: var(--color-text-secondary);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;

    @media (max-width: $break-medium) {
      gap: 8px;
      margin-top: 8px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &Title {
      font-weight: bold;
      color: var(--color-text-secondary);
      font-size: 12px;
      line-height: 1;
      margin-bottom: 4px;
    }

    &Value {
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1;
      font-size: 14px;

      a {
        color: var(--color-blue-20);
      }
    }

    &Subtitle {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1;

      a {
        color: var(--color-text-primary);
      }
    }
  }

  &--goal &__card {
    border: 1px solid var(--color-green);
    background: var(--color-green-overlay-08);
  }

  &--goal &__title {
    color: var(--color-green-10);
  }

  &--penalty {

  }

  &--red &__card,
  &--penalty &__card {
    border: 1px solid var(--color-red);
    background: var(--color-red-overlay-08);
  }

  &--red &__title,
  &--penalty &__title {
    color: var(--color-red);
  }

  &--yellow &__card {
    border: 1px solid var(--color-yellow-native);
    background: var(--color-yellow-native-overlay-08);
  }

  &--yellow &__title {
    color: var(--color-yellow-native-10);
  }

  &--yellow &__title i {
    color: var(--color-yellow-native);
  }
}
