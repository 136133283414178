

.MatchEvents {
  margin: 80px 0;
  position: relative;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 140px;
    left: 50%;
    bottom: 40px;
    border-left: 1px dashed var(--color-grey-20);
    transform: translateX(-50%);

    @media (max-width: $break-medium) {
      left: 40px;
    }

    @media (max-width: $break-small) {
      left: 34px;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 140px;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-grey-20);
    transform: translate(-50%, -50%);
    z-index: 1;

    @media (max-width: $break-medium) {
      left: 40px;
    }

    @media (max-width: $break-small) {
      left: 34px;
    }
  }

  &--list:before,
  &--list:after {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;

    @media (max-width: $break-medium) {
      gap: 8px;
    }
  }

  &__title {
    margin: 0;
    text-align: center;
  }

  &__content {
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;

    @media (max-width: $break-medium) {
      margin: 40px 0;
    }
  }

  &--list &__content {
    margin-top: 24px;
  }

  &__separator {
    display: flex;
    align-content: center;
    justify-content: center;
    align-self: center;
    margin: 40px 0;
    background: rgb(235, 235, 242);
    padding: 8px;
    color: var(--color-text-secondary);
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    border-radius: 4px;
    position: relative;
    z-index: 1;

    &--circle {
      border-radius: 50%;
      padding: 0;
    }

    @media (max-width: $break-medium) {
      align-self: flex-start;
      margin: 24px 0;
      position: relative;
      left: 0;

      &--circle {
        top: -2px;
        left: 2px;
      }
    }

    @media (max-width: $break-small) {
      margin: 0;

      &--circle {
        top: -2px;
        left: 12px;
      }
    }
  }

  &__separator + &__separator {
    margin-top: -40px;

    @media (max-width: $break-medium) {
      margin-top: -24px;
    }

    @media (max-width: $break-small) {
      margin-top: -16px;
    }
  }

  &__table {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    margin: 16px 0;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 24px;
      margin: 0;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-grey);
    border-radius: 8px;
    box-shadow: 5.4px 5.2px 15px 0 rgb(155 155 155 / 21%);
  }

  &__list {
    display: flex;
    flex-direction: column;

    &Title {
      display: flex;
      align-items: center;
      padding: 4px 16px;
      background: var(--color-blue-overlay-04);
      color: var(--color-text-secondary);
      font-weight: normal;
      font-size: 12px;
    }

    &Empty {
      padding: 16px;
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    &Item {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;

      &:hover {
        background: var(--color-blue-overlay-08);
      }

      &Type {
        color: var(--color-text-secondary);
      }

      &Player {
        font-size: 14px;
        color: var(--color-text-primary);
        font-weight: bold;
        flex-grow: 1;

        span {
          font-weight: normal;
        }
      }

      &Time {
        color: var(--color-text-secondary);
        font-size: 14px;
      }
    }
  }
}
