
div.smartbanner-android {
  background: #000555;
  box-shadow: none;

  .smartbanner-button {
    box-shadow: none;
    text-shadow: none;
  }

  .smartbanner-button-text {
    background: var(--color-yellow);
    color: var(--color-text-primary);
    padding: 6px 12px;
    border-radius: 2px;
  }

  .smartbanner-title {
    font-weight: bold;
  }

  .smartbanner-close {
    box-shadow: none;
    background: var(--color-white-40);
    color: var(--color-blue-20);
    text-shadow: none;
  }
}

div.smartbanner-container {
  padding: 0 16px;
}
