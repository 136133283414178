

.Newsletter {
  background: var(--color-white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__container {
    background: var(--color-blue-20);
    padding-bottom: 64px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 480px;
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @media (max-width: $break-large) {
      flex-direction: column;
    }
  }

  h2,
  p {
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;

    @media (max-width: $break-small) {
      flex-direction: column;
    }
  }

  .FormField {
    margin: 0;
  }
}
