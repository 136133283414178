
.MatchHeaderAlt {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  background: var(--color-blue-20);
  background: linear-gradient(100deg, var(--color-blue-10) 50%, var(--color-blue-20) 50%);
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
  border-radius: 8px;
  position: relative;
  z-index: 1;

  @media (max-width: $break-small) {
    background: linear-gradient(120deg, var(--color-blue-10) 50%, var(--color-blue-20) 50%);
  }

  @media (max-width: $break-xsmall) {
    margin: 0 -16px;
    border-radius: 0;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    position: relative;
    z-index: 1;

    @media (max-width: $break-xsmall) {
      display: none;
    }

    h1 {
      margin: 0;

      &:before {
        background: url("~@amf/shared/assets/icon-cross-blue.svg") no-repeat center;
        background-size: 24px 24px;
      }
    }

    @media (max-width: $break-small) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 24px;

      a {
        width: 100%;
      }
    }
  }

  &__layout {
    position: relative;

    @media (max-width: $break-xsmall) {
      margin-top: -40px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -80px;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--color-yellow);

      @media (max-width: $break-xsmall) {
        display: none;
      }
    }

    h1 {
      position: relative;
      z-index: 1;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    gap: 24px;
    border-bottom: 1px solid var(--color-white-16);
    padding: 16px 24px;

    @media (max-width: $break-small) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
    }

    &League {
      text-align: center;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      line-height: 1;
      gap: 4px;

      &Name {
        font-size: 14px;
        font-family: $font-primary;
        color: var(--color-yellow);
      }

      &Phase {
        font-size: 12px;
        font-weight: bold;
        color: var(--color-white-40);
      }
    }

    &Time {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: left;
      line-height: 1;
      font-size: 14px;
      font-weight: bold;

      @media (max-width: $break-small) {
        flex-direction: row;
        justify-content: center;
        text-align: center;
      }
    }

    &Ground {
      line-height: 1;
      font-size: 14px;
      font-weight: bold;
      text-align: right;

      @media (max-width: $break-small) {
        text-align: center;
      }

      &Title {
        color: var(--color-white-40);
      }

      a {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        color: currentColor;

        @media (max-width: $break-small) {
          align-items: center;
        }

        span {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        small {
          max-width: 96px;
          font-size: 10px;
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 1fr);
    text-align: center;
    padding: 24px;
    gap: 24px;

    @media (max-width: $break-normal) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding: 16px;
    }

    @media (max-width: $break-small) {
      gap: 16px;
    }
  }

  &__team {
    color: currentColor;
    border-radius: 8px;
    padding: 24px;
    transition: background-color ease-in 100ms;
    gap: 4px;
    line-height: 1;
    position: relative;

    @media (max-width: $break-small) {
      padding: 0;
    }

    &Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      position: relative;
    }

    &Image {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: bold;
      font-family: $font-primary;
      text-transform: uppercase;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      padding: 12px;
      background: var(--color-white-16);
      color: var(--color-white-32);
      margin-bottom: 16px;

      @media (max-width: $break-normal) {
        width: 96px;
        height: 96px;
      }

      @media (max-width: $break-small) {
        width: 64px;
        height: 64px;
        padding: 4px;
        font-size: 24px;
        margin-bottom: 8px;
      }
    }

    &Name {
      gap: 4px;
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;

      @media (max-width: $break-normal) {
        font-size: 16px;
        line-height: 20px;
      }

      @media (max-width: $break-small) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &Dress {
      margin-top: 8px;
      display: block;

      @media (max-width: $break-small) {
        display: none;
      }

      &,
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &Club {
      text-transform: uppercase;
      color: var(--color-white-40);
      font-weight: bold;
      font-size: 14px;

      @media (max-width: $break-normal) {
        font-size: 12px;
      }

      @media (max-width: $break-small) {
        display: none;
      }
    }

    &:hover {
      background: var(--color-white-08);
    }

    &Crosses {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media (max-width: $break-small) {
        display: none;
      }
    }

    &Cross {
      background: url("../../../assets/icon-cross-outline.svg") no-repeat center;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      position: absolute;
      opacity: .40;

      &:nth-child(1) {
        right: 16%;
        top: 20px;
      }

      &:nth-child(2) {
        left: 25%;
        bottom: 40px;
      }

      &:nth-child(3) {
        right: 85%;
        top: 40px;
      }

      &:nth-child(4) {
        right: 25%;
        top: 120px;
      }

      &:nth-child(5) {
        right: 4%;
        bottom: 40px;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation: floatingCross ease-in-out 8s infinite;
          animation-delay: -600ms * $i;
          will-change: transform;
        }
      }
    }
  }

  &__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    gap: 8px;

    @media (max-width: $break-small) {
      gap: 4px;
      justify-content: flex-start;
      padding-top: 12px;
    }

    &Time {
      font-size: 16px;
      color: var(--color-white-40);
      font-family: $font-primary;

      i.bi {
        margin-right: 6px;
      }
    }

    &Score {
      font-size: 48px;
      font-family: $font-primary;
      font-weight: bold;

      @media (max-width: $break-small) {
        font-size: 32px;
      }

      &--small {
        font-size: 32px;

        @media (max-width: $break-small) {
          font-size: 24px;
        }
      }
    }

    &HalfTime {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-white-40);

      @media (max-width: $break-small) {
        font-size: 12px;
      }

      .MatchJumbotron__timer {
        font-size: inherit;
        margin-top: 4px;
      }

      .MatchJumbotron__status {

        &Inner {
          background: none;
          box-shadow: none;
          padding: 0;
          font-size: inherit;
          line-height: 1;
          max-width: none;
          height: auto;
        }
      }
    }

    &Now {
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: uppercase;
      font-family: $font-primary;
      font-size: 14px;
      margin: 16px 0;
      color: var(--color-yellow);

      @media (max-width: $break-small) {
        flex-direction: column;
        font-size: 12px;
        margin: 12px 0;
        line-height: 16px;
        text-align: center;
      }
    }

    &Other {
      font-size: 16px;
      font-weight: bold;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      background: var(--color-white-08);
      padding: 8px;
      border-radius: 4px;
      width: 100%;

      @media (max-width: $break-small) {
        display: none;
      }

      &Title {
        color: var(--color-white-40);
        font-size: 14px;
        margin-bottom: 4px;
      }
    }

    &Referee {
      display: flex;
      align-items: center;
      gap: 4px;

      a {
        color: currentColor;
      }

      span small,
      a small {
        color: var(--color-white-40);
      }
    }
  }

  &__referees {
    font-size: 16px;
    font-weight: bold;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    background: var(--color-white-08);
    padding: 8px;
    border-radius: 4px;
    margin: 0 16px 16px;
    display: none;

    @media (max-width: $break-small) {
      display: flex;
    }
  }

  &__cta {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &Helper {
      display: none;
      margin-bottom: 16px;

      @media (max-width: $break-small) {
        display: block;
      }
    }
  }

  &__buttonLive {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    appearance: none;
    border: none;
    background: var(--color-red);
    color: var(--color-white);
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 16px;
    border-radius: 8px;
    height: 40px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    transition: transform ease-in $animation-speed, box-shadow ease-in $animation-speed;
    will-change: transform, box-shadow;
    white-space: nowrap;

    &:active,
    &:active:focus {
      transform: scale3d(0.99, 0.99, 1);
    }

    &[disabled] {
      cursor: default;
      background: var(--color-red-10);
      color: var(--color-white-40);

      &:active,
      &:active:focus {
        transform: none;
      }
    }
  }
}
