
.LeagueHeader {
  background: var(--color-blue-20);
  color: var(--color-white);
  margin-top: -64px;
  padding: 64px 0;

  @media (max-width: $break-small) {
    margin-top: -40px;
    padding: 16px 0 40px;
  }

  &__title {
    color: currentColor;
    font-size: 40px;
    line-height: 40px;
    margin: 0;

    @media (max-width: $break-small) {
      font-size: 32px;
      line-height: 32px;
      text-align: center;
    }

    &:before {
      display: none;
    }
  }

  &__icon {
    color: var(--color-yellow);
    font-size: 56px;
    line-height: 56px;
    display: block;

    @media (max-width: $break-small) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  &__subtitle {
    color: var(--color-white-40);
    font-family: $font-primary;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;

    @media (max-width: $break-small) {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    @media (max-width: $break-small) {
      flex-direction: column;
      justify-content: center;
      gap: 16px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column-reverse;
      gap: 40px;

      .Button,
      a {
        width: 100%;
      }
    }
  }

  &__crosses {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__cross {
    background: url("../../../assets/icon-cross-outline.svg") no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    position: absolute;

    &:nth-child(1) {
      right: 16%;
      top: -20px;
    }

    &:nth-child(2) {
      right: 50%;
      bottom: -24px;
    }

    &:nth-child(3) {
      right: 85%;
      top: -40px;
    }

    &:nth-child(4) {
      right: 30%;
      top: 0;
    }

    &:nth-child(5) {
      right: 4%;
      bottom: -40px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }
  }

  .Container {
    overflow: visible;
  }

  .Button {
    position: relative;
    z-index: 1;
  }
}

@keyframes floatingCross {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: none;
  }
}
