

.Copyright {
  position: relative;
  background: var(--color-blue-10);
  color: var(--color-white);
  padding: 24px 0;

  @media (max-width: $break-small) {
    padding: 56px 0 24px;
  }

  &__icon {
    position: absolute;
    top: -32px;
    left: 50%;
    width: 64px;
    height: 64px;
    background: var(--color-blue-10);
    transform: translateX(-50%);
    border-radius: 50%;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: var(--color-blue-20);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__about {
    color: currentColor;
    font-size: 14px;
    line-height: 24px;

    a {
      color: var(--color-green);
      font-weight: bold;
    }
  }

  &__author {
    display: block;
    text-indent: -9999px;
    background: url("../../../assets/brand-elasticle.svg") no-repeat center;
    background-size: 95px 11px;
    width: 95px;
    height: 24px;
  }
}
