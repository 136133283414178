

.MatchHeader {
  background: var(--color-blue-20);
  margin-top: -64px;

  @media (max-width: $break-small) {
    margin-top: -40px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 120px 0;
    position: relative;

    @media (max-width: $break-normal) {
      padding: 80px 0;
    }

    @media (max-width: $break-small) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      text-align: center;
      padding: 40px 0 64px;
    }
  }

  &__team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: relative;
    z-index: 1;

    @media (max-width: $break-medium) {
      gap: 16px;
    }

    @media (max-width: $break-small) {
      gap: 0;
    }

    &Info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
    }

    &Name {
      margin: 0;
      color: var(--color-white);

      @media (max-width: $break-small) {
        font-size: 20px;
      }
    }

    &Club {
      color: var(--color-white-40);
      font-weight: bold;

      @media (max-width: $break-small) {
        font-size: 14px;
      }
    }
  }

  &__dress {
    display: block;
    width: 128px;
    height: 128px;
    position: relative;

    @media (max-width: $break-medium) {
      width: 80px;
      height: 80px;
    }

    @media (max-width: $break-small) {
      width: 40px;
      height: 40px;
    }

    svg {
      position: absolute;
      width: 64px;
      height: 64px;
      bottom: -16px;
      right: -16px;
      object-fit: contain;
      background: var(--color-black);
      border-radius: 50%;
      padding: 8px;

      @media (max-width: $break-medium) {
        width: 40px;
        height: 40px;
        bottom: -8px;
        right: -8px;
      }

      @media (max-width: $break-small) {
        display: none;
      }
    }
  }

  &__brand {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--color-white);
    background: var(--color-grey-20);
  }

  &__score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    z-index: 1;

    @media (max-width: $break-small) {
      gap: 4px;
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      line-height: 1;
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--color-white-40);
      width: 100%;
      text-align: center;
      font-size: 14px;

      &--large {
        font-size: 80px;
        color: var(--color-white);
        justify-content: center;

        @media (max-width: $break-medium) {
          font-size: 64px;
        }

        @media (max-width: $break-small) {
          font-size: 32px;
        }
      }

      &--medium {
        font-size: 32px;
        color: var(--color-white);
        justify-content: center;
        margin-bottom: 24px;

        @media (max-width: $break-medium) {
          font-size: 24px;
        }

        @media (max-width: $break-small) {
          font-size: 16px;
          margin: 0;
        }
      }

      &Number {
        color: var(--color-white);
      }

      &--medium &Number {
        color: var(--color-white-40);
      }
    }
  }

  &__crosses {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__cross {
    background: url("../../../assets/icon-cross-outline.svg") no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: .7;

    &:nth-child(1) {
      right: 16%;
      top: 20px;
    }

    &:nth-child(2) {
      left: 25%;
      bottom: 40px;
    }

    &:nth-child(3) {
      right: 85%;
      top: 40px;
    }

    &:nth-child(4) {
      right: 55%;
      top: 40px;
    }

    &:nth-child(5) {
      right: 4%;
      bottom: 40px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }
  }
}
