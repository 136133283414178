

.MatchListItem {
  --gap: 24px;
  --brand-size: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
  width: 100%;
  padding: 16px 40px 16px 16px;
  height: 72px;
  border: 1px solid var(--color-grey-10);
  gap: var(--gap);
  margin: 0;
  position: relative;

  @media (max-width: $break-normal) {
    grid-template-columns: 1fr 0.5fr 1fr;
    height: 104px;
    padding-top: 40px;
    padding-bottom: 0;
  }

  @media (max-width: $break-small) {
    --gap: 8px;
    --brand-size: 24px;

    grid-template-columns: 1fr 1fr 1fr;
    height: auto;
    padding: 40px 8px 8px;
  }

  & + .MatchListItem {
    border-top: none;
  }

  &:hover {
    background: var(--color-blue-overlay-04);
  }

  &--loading {
    cursor: progress;
  }

  &--loading:hover {
    background: none;
  }

  &--live {
    background: var(--color-yellow-overlay-08);
    border: 1px solid var(--color-yellow) !important;
    box-shadow: 3.4px 2.2px 10px 0 rgb(255 200 27 / 18%);

    &:hover {
      background: var(--color-yellow-overlay-16);
    }
  }

  &__team {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;

    @media (max-width: $break-small) {
      justify-content: flex-start;
      flex-direction: row-reverse;
    }

    &--home {
      flex-direction: row-reverse;

      @media (max-width: $break-small) {
        flex-direction: row;
      }
    }

    &Name {
      margin: 0;
      text-align: left;
      color: var(--color-text-primary);

      @media (max-width: $break-small) {
        font-size: 12px;
        text-align: right;
      }
    }

    &--home &Name {
      text-align: right;
    }

    @media (max-width: $break-small) {
      &--home &Name {
        text-align: left;
      }
    }

    &Brand {
      display: block;
      width: var(--brand-size);
      min-width: var(--brand-size);
      height: var(--brand-size);
      background: var(--color-blue-overlay-04);
      border-radius: 50%;
    }

    &--empty &Name {
      color: var(--color-grey-20);
    }
  }

  &--loading &__teamBrand,
  &--loading &__teamName {
    @include shimmer(3s, 512px, var(--color-grey), var(--color-grey-10), padding-box);
  }

  &--loading &__teamName {
    height: 20px;
    border-radius: 6px;
    width: 40%;
  }

  &__time,
  &__score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary);
    text-align: center;
    gap: 6px;

    span,
    small {
      line-height: 1;
    }

    small {
      color: var(--color-text-secondary);
    }
  }

  &--loading &__time span {
    @include shimmer(3s, 512px, var(--color-grey), var(--color-grey-10), padding-box);
    height: 18px;
    width: 40%;
    border-radius: 6px;
  }

  &--loading &__time small {
    @include shimmer(3s, 512px, var(--color-grey), var(--color-grey-10), padding-box);
    border-radius: 6px;
    height: 14px;
    width: 80%;
  }

  &__score {

    span {
      font-size: 18px;
    }

    small {
      color: var(--color-grey-20);
    }
  }

  &--live &__score small {
    color: var(--color-yellow-10);
  }

  &__chevron {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-grey-20);
    width: 40px;
    user-select: none;

    @media (max-width: $break-small) {
      display: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: $break-normal) {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      padding: 8px;
    }

    &Item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 11px;
      background: var(--color-blue-overlay-08);
      padding: 4px;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--color-blue-disabled);
      border-radius: 4px;

      &--red {
        background: var(--color-red-overlay-08);
        color: var(--color-red);
      }
    }
  }

  &__main {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $break-normal) {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      padding: 8px;
    }

    @media (max-width: $break-small) {
      justify-content: space-between;
      gap: 8px;
    }
  }

  &__round {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text-primary);
    font-family: $font-primary;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__ground {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--color-text-secondary);

    &Icon {
      font-size: 16px;
    }

    &Content {
      display: flex;
      flex-direction: column;
      gap: 2px;

      span {
        font-size: 14px;
      }
    }
  }
}

