

.Button {
  display: inline-flex;
  align-items: center;
  appearance: none;
  overflow: hidden;
  font-stretch: normal;
  font-style: normal;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: $font-primary;
  position: relative;
  transition: transform ease-in $animation-speed, box-shadow ease-in $animation-speed;
  will-change: transform, box-shadow;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.0024em;
  height: 44px;
  padding: 8px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), inset 0 1px 0 rgba(255, 255, 255, 0.48);
  gap: 4px;
  user-select: none;

  @media (max-width: $break-small) {
    width: 100%;
    justify-content: center;
  }

  &:active,
  &:active:focus {
    transform: scale3d(0.99, 0.99, 1);
  }

  &--blue {
    background: var(--color-blue);
    color: var(--color-white);
    box-shadow: none;
  }

  &--blueDark {

  }

  &--blueOutline {
    background: transparent;
    border: 2px solid var(--color-blue-10);
    color: var(--color-blue-10);
    box-shadow: none;
  }

  &--whiteOutline {
    background: transparent;
    border: 2px solid var(--color-white);
    color: var(--color-white);
    box-shadow: none;
  }

  &--darkOutline {
    background: transparent;
    border: 2px solid var(--color-text-primary);
    color: var(--color-text-primary);
    box-shadow: none;
  }

  &--green {
    background-color: var(--color-green);
    color: var(--color-blue-10);
  }

  &--dark {
    background: var(--color-text-primary);
    color: var(--color-white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }

  &--yellow {
    background: var(--color-yellow);
    color: var(--color-text-primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }

  &--small {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.0024em;
    height: 24px;
    padding: 8px 8px;
    justify-content: center;
    border-radius: 2px;
  }

  &[disabled] {
    color: var(--color-grey-20);
    background: var(--color-grey);
  }

  &__content {
    display: block;
    color: inherit;
    margin-top: 2px;
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;

      * {
        fill: var(--color-blue-10);
      }
    }

    &--left {
      margin-left: -8px;
    }
  }

  &--whiteOutline svg * {
    fill: var(--color-white);
  }
}
