
.RefereeList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 40px 0;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}

.RefereeListItem {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--color-grey);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: var(--color-blue-overlay-04);
  }

  &__photo {
    width: 40px;
    height: 40px;

    img,
    div {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      border-radius: 50%;
      background: var(--color-blue-overlay-08);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-blue-20);
      font-weight: 900;
      user-select: none;
      transition: box-shadow ease-in-out $animation-speed;
      will-change: box-shadow;
    }
  }

  &__info {

  }

  &__name {
    margin: 0;
    font-size: 16px;

    span {
      display: inline-block;
      background: var(--color-blue-overlay-08);
      padding: 4px;
      font-size: 14px;
      line-height: 1;
      border-radius: 4px;
      font-weight: normal;
      position: relative;
      top: -2px;
      margin-left: 4px;
    }
  }

  &__about {
    color: var(--color-text-secondary);
  }
}
