

.PlayerDashboardGrid {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    @media (max-width: $break-medium) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column-reverse;
      gap: 16px;
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  &__title,
  &__field {
    margin: 0;

    @media (max-width: $break-small) {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    gap: 24px;

    @media (max-width: $break-medium) {
      flex-direction: column;
    }
  }

  &__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-grow: 1;
    border: 1px solid var(--color-grey);

    &--landscape {
      flex-direction: row;
      align-items: stretch;

      @media (max-width: $break-small) {
        flex-direction: column;
        align-items: center;
      }
    }

    &Name {
      margin: 0;
      padding-top: 24px;
    }

    &Promoted {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      width: 100%;
    }

    &--landscape &Promoted {
      padding-bottom: 24px;

      @media (max-width: $break-small) {
        padding-bottom: 0;
      }
    }
  }

  &__player {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    &Avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 92px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--color-grey);
      color: var(--color-grey-20);
      font-size: 40px;
      line-height: 40px;
      font-family: $font-primary;
    }

    &Name {
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 16px;
      color: var(--color-blue-20);
      font-weight: bold;
    }

    &Team {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 14px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-text-secondary);
    }

    &Dress {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--color-blue-20);

      svg {
        display: block;
        width: 80%;
        height: 80%;
      }
    }

    &Count {
      font-size: 40px;
      line-height: 40px;
      white-space: nowrap;
      font-family: $font-primary;
      margin-top: 16px;
      color: var(--color-blue-20);
    }
  }

  &__table {
    border-top: 1px solid var(--color-grey);
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid var(--color-grey);
    }

    tr:last-child th,
    tr:last-child td {
      border-bottom: none;
    }

    th {
      text-align: left;
      padding: 8px 16px;

      a {
        display: flex;
        flex-direction: column;
        gap: 2px;
        color: var(--color-blue-20);

        small {
          font-size: 12px;
          color: var(--color-text-secondary);
        }

        span {

        }
      }
    }

    td {
      text-align: right;
      padding: 8px 16px;
      font-family: $font-primary;
    }
  }

  &__type--landscape &__table {
    border-top: none;
    border-left: 1px solid var(--color-grey);

    @media (max-width: $break-small) {
      border-left: none;
      border-top: 1px solid var(--color-grey);
    }
  }
}
