
.ClubHeader {

  &__content {
    justify-content: center !important;
    padding-top: 64px !important;
    padding-bottom: 80px !important;
  }

  &__brand {
    position: static;
    width: 80px;
    height: 80px;
  }
}
