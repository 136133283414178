

.Search {


  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__form {
    position: relative;
  }

  &__submit {
    position: absolute;
    top: 4px;
    right: 4px;

    i {
      font-size: 16px !important;
    }
  }

  &__input {
    appearance: none;
    display: block;
    width: 100%;
    height: 48px;
    background: var(--color-white);
    box-shadow: 3px 3px 15px 0 rgb(0 0 0 / 12%);
    font-size: 16px;
    font-weight: 500;
    padding: 12px 64px 12px 16px;
    outline: none;
    border: 1px solid var(--color-grey-10);

    &:focus {
      border-color: var(--color-grey-20);
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 16px;

      @media (max-width: $break-medium) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media (max-width: $break-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
}
