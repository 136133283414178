
.PlayerHeader {

  &__content {
    justify-content: center !important;
    padding-top: 64px !important;
    padding-bottom: 80px !important;

    @media (max-width: $break-small) {
      padding-top: 40px !important;
      padding-bottom: 64px !important;
    }
  }

  &__about {
    flex-direction: column;
  }

  &__avatar {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: var(--color-blue-20);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: $font-primary;
    text-transform: uppercase;
    color: var(--color-text-secondary);
    font-weight: 900;
    user-select: none;
  }
}
