

.MatchPreviousList {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: $break-small) {
    margin: 40px 0;
    gap: 16px;
  }

  &__title {
    margin: 0;

    &--center {
      text-align: center;
    }
  }

  &__matches {
    width: 100%;
  }

  .MatchTable {
    margin: 0;
  }
}
