
.PlayerStats,
.PlayerLastMatches {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__title {
    margin: 24px 0;

    @media (max-width: $break-small) {
      margin: 16px 0;
    }
  }

  &__list {
    border: 1px solid var(--color-grey);
    border-bottom: none;
    width: 100%;
    min-width: 800px;
  }

  &__content {
    overflow-x: auto;
  }

  &__item,
  &__header {
    display: grid;
    grid-template-columns: 2fr 2fr repeat(6, minmax(0, 1fr));
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid var(--color-grey);
    white-space: nowrap;

    @media (max-width: $break-small) {
      grid-template-columns: 1fr 1fr repeat(6, minmax(0, 1fr));
      padding: 8px;
    }

    div:first-child,
    div:nth-child(2) {
      text-align: left;
    }
  }

  &--goalKeeper &__item,
  &--goalKeeper &__header {
    grid-template-columns: 2fr repeat(4, minmax(0, 1fr));

    @media (max-width: $break-small) {
      grid-template-columns: 1fr repeat(4, minmax(0, 1fr));
    }
  }

  &__header {
    background: var(--color-black-04);
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__item {
    color: var(--color-text-primary);

    div:first-child,
    div:nth-child(2) {
      font-weight: bold;
    }

    &:hover {
      background: var(--color-blue-overlay-04);
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--color-grey);
    border-bottom: none;
  }

  th,
  td {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid var(--color-grey);
    white-space: nowrap;

    @media (max-width: $break-small) {
      padding: 8px;
    }

    &.left {
      text-align: left;
    }

    &.fs-normal {
      font-size: 16px;
    }

    a {
      color: var(--color-blue-20);
    }

    i {
      margin-left: 6px;
    }
  }

  th {
    background: var(--color-black-04);
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  td {
    color: var(--color-text-primary);
  }

  &__league {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__team {

  }
}
