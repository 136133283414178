$field-height: 56px;
$field-height-small: 44px;

.FormField {
  display: inline-block;
  position: relative;
  margin-bottom: 24px;

  &--check,
  &--radio {
    display: flex;
  }

  &--radio + &--radio {
    margin-top: -12px;
  }

  &__input {
    display: block;
    width: 100%;
    height: $field-height;
    border: 1px solid var(--color-black-16);
    font-style: normal;
    font-family: $font-native;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    appearance: none;
    color: var(--color-black);
    padding: 24px 56px 8px 16px;
    background-color: var(--color-white);
    background-clip: padding-box;
    border-radius: 0;
    letter-spacing: 0.4px;
    outline: none;

    &::-webkit-calendar-picker-indicator {
      opacity: .32;
      position: relative;
      left: 41px;
      bottom: 7px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    option {
      color: var(--color-black);
      background: var(--color-white);
    }

    &--textArea {
      min-height: 220px;
      resize: none;
    }

    &[disabled] {
      border-style: dashed;
      background: var(--color-black-08);
    }
  }

  &--loading &__input {
    background: linear-gradient(110deg, rgb(131, 240, 163, .08) 8%, rgb(131, 240, 163, .32) 18%, rgb(131, 240, 163, .08) 33%);
    background-size: 200% 100%;
    animation: 1.5s FormFieldLoading linear infinite;
  }

  &--small &__input {
    height: $field-height-small;
    padding: 18px 56px 2px 16px;
  }

  &__check {
    display: none;

    & + label:before {
      content: "";
      display: block;
      background: no-repeat center;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
    }

    &[type=checkbox] + label:before {
      background-image: $checkbox-dark-unchecked;
    }

    &[type=radio] + label:before {
      background-image: $radio-dark-unchecked;
    }

    &[type=checkbox]:checked + label:before {
      background-image: $checkbox-dark-checked;
    }

    &[type=radio]:checked + label:before {
      background-image: $radio-dark-checked;
    }

    &Label {
      position: relative;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: var(--color-text-primary);
      padding-left: 36px;

      a {
        color: var(--color-blue-20);
        text-decoration: underline;
      }
    }
  }

  &--dark &__check[type=checkbox] + label:before {
    background-image: $checkbox-light-unchecked;
  }

  &--dark &__check[type=radio] + label:before {
    background-image: $radio-light-unchecked;
  }

  &--dark &__check[type=checkbox]:checked + label:before {
    background-image: $checkbox-light-checked;
  }

  &--dark &__check[type=radio]:checked + label:before {
    background-image: $radio-light-checked;
  }

  &--dark &__checkLabel a {
    color: var(--color-yellow);
  }

  select {
    -moz-padding-start: 14px;
  }

  &--dark &__input {
    border: 1px solid var(--color-white-16);
    color: var(--color-white);
    background-color: transparent;

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    option {
      color: var(--color-white);
      background: var(--color-grey-20);
    }

    &[disabled] {
      background: var(--color-white-08);
    }
  }

  &--dark &__checkLabel {
    color: var(--color-white);

    a {
      color: var(--color-white);
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: text;
    max-width: 66.66%;
    padding: 16px;
    line-height: 24px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity $animation-speed ease-in-out, transform .1s ease-in-out;
    color: var(--color-black);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.4px;
    opacity: .4;
    will-change: opacity, transform;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--small &__label {
    padding: 10px 16px;
  }

  &--dark &__label {
    color: var(--color-white);
  }

  &__input,
  &__label {
    touch-action: manipulation;
  }

  &__input:not(&--loading &__input):focus {
    border: 2px solid var(--color-green);
    background: var(--color-green-overlay-08);
    padding: 23px 55px 7px 15px;
  }

  select:focus {
    -moz-padding-start: 13px;
  }

  &__input::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  &__input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  &__input:not(:placeholder-shown) + label,
  &__input:focus + label {
    opacity: .56;
    transform: scale(.75) translateY(-5px) translateX(6px);
    cursor: pointer;
  }

  &--withError &__input,
  &__input:invalid {
    border-color: var(--color-red);
    background: var(--color-red-overlay-08);
    border-width: 2px;
    padding: 23px 55px 7px 15px;
    box-shadow: none;
  }

  &__accessory {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 8px;
    display: flex;
    gap: 8px;
    color: var(--color-black-32);
    line-height: 40px;
    text-align: center;
    justify-content: center;
    min-width: 56px;
    pointer-events: none;

    &Icon {
      margin: 8px;
    }

    .ButtonIcon {
      pointer-events: auto;

      i {
        color: var(--color-black-32);
      }
    }

    &Icon *,
    .ButtonIcon svg * {
      fill: var(--color-black-32);
    }
  }

  &--small &__accessory {
    padding: 2px;
  }

  &--dark &__accessory {
    color: var(--color-white-32);
  }

  &--dark &__accessoryIcon *,
  &--dark &__accessory .ButtonIcon svg * {
    fill: var(--color-white-32);
  }

  &__estimator {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 4px;
    margin: 4px;
    height: 4px;

    &Segment {
      display: block;
      height: 100%;
      background: var(--color-black-16);
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        transition: background-color $animation-speed ease-in-out, transform $animation-speed ease-in-out;
        transform: scaleX(0);
        transform-origin: left top;
      }

      &--highlighted:after {
        background: var(--color-green);
        transform: scaleX(1);
      }
    }
  }

  &--dark &__estimatorSegment {
    background: var(--color-white-16);
  }

  &__error {
    color: var(--color-red);
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 12px;
    letter-spacing: 0.4px;
    padding: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@keyframes FormFieldLoading {
  to {
    background-position-x: -200%;
  }
}
