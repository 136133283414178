
.Form {
  padding: 24px;
  background: var(--color-grey);
  flex-basis: 66%;
  flex-grow: 1;
  min-height: 256px;

  @media (max-width: $break-large) {
    margin-bottom: 40px;
  }

  @media (max-width: $break-small) {
    padding: 16px;
  }

  &--center {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__row {
    display: flex;
    gap: 24px;

    @media (max-width: $break-medium) {
      display: block;
    }
  }

  &__col {
    flex-grow: 1;
  }

  &__field {
    width: 100%;
  }

  .FormField__input:not(.FormField--loading .FormField__input):focus {
    background: rgb(245 254 248);
  }

  &__message {
    color: var(--color-text-secondary);
    max-width: 320px;
    line-height: 20px;
  }
}