

.MatchPlayer {

  &__preview {

  }

  &__control {
    margin: 40px 0;
  }

  &__slider {

    .MuiSlider-thumb {

    }
  }
}
