

.ContractList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin: 64px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__empty {
    border: 1px solid var(--color-blue-overlay-08);
    background: var(--color-blue-overlay-04);
    color: var(--color-text-secondary);
    font-size: 14px;
    padding: 16px;
    text-align: center;
  }
}

.Contract {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  padding: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__date {
    color: var(--color-text-secondary);
    font-size: 14px;
  }

  &__type {
    margin: 0;

    @media (max-width: $break-small) {
      font-size: 16px;
    }
  }

  &__message {
    margin: 8px 0;
  }

  &__player {
    padding: 16px;
    background: var(--color-black-04);
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 8px;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &Section {
      display: flex;
      flex-direction: column;
    }

    &Label {
      font-size: 14px;
      color: var(--color-text-secondary)
    }

    &Value {
      color: var(--color-text-primary);

      a {
        color: var(--color-blue);
      }
    }
  }
}


.ContractHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;

  @media (max-width: $break-medium) {
    flex-direction: column;
    align-items: flex-start;

    .Button, a {
      width: 100%;
    }
  }

  h1 {
    margin: 0;
  }
}
