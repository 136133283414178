

.Contumacy {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid var(--color-yellow);
  background: var(--color-yellow-overlay-16);
  padding: 24px;

  @media (max-width: $break-small) {
    margin: 40px 0;
    padding: 16px;
  }

  &__title {
    color: var(--color-black);
    margin: 0;
  }

  &__message {
    color: var(--color-black-80);
  }
}
