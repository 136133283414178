

.Table {
  margin: 24px 0;

  .MatchTable {
    margin: 16px 0;
  }

  &__pagination {
    margin: 24px 0;
    text-align: center
  }
}
