

.TeamPlayerStats {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $break-normal) {
    margin: 40px 0;
  }

  &__title {
    margin: 0;

    &--center {
      text-align: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .FormField {
      margin: 0;

      @media (max-width: $break-small) {
        width: 100%;
      }
    }
  }

  &__promotedList {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 24px;

    @media (max-width: $break-normal) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__promotedItem {
    border: 1px solid var(--color-grey);
  }

  &__player {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding: 24px;

    &Avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 92px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-bottom: 16px;
      background: var(--color-grey);
      color: var(--color-grey-20);
      font-size: 40px;
      line-height: 40px;
      font-family: $font-primary;
    }

    &Name {
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 16px;
      color: var(--color-blue-20);
      font-weight: bold;
    }

    &Subtitle {
      color: var(--color-text-secondary);
    }

    &Count {
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      font-family: $font-primary;
      margin-top: 16px;
      color: var(--color-blue-20);
      text-transform: uppercase;
    }
  }

  &__promotedTable {
    border-top: 1px solid var(--color-grey);
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid var(--color-grey);
    }

    tr:last-child th,
    tr:last-child td {
      border-bottom: none;
    }

    th {
      text-align: left;
      padding: 8px 16px;

      @media (max-width: $break-small) {
        padding: 8px;
      }

      a {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-size: 14px;
        color: var(--color-blue-20);
      }
    }

    td {
      text-align: right;
      padding: 8px 16px;
      font-family: $font-primary;
      font-size: 14px;

      @media (max-width: $break-small) {
        padding: 8px;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
    margin: 24px 0;

    @media (max-width: $break-normal) {
      grid-template-columns: minmax(0, 1fr);
      border-bottom: 1px solid var(--color-grey);
    }

    @media (max-width: $break-small) {
      grid-column-gap: 16px;
      margin: 16px 0;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    border-bottom: 1px solid var(--color-grey);

    @media (max-width: $break-small) {
      gap: 16px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__cell {
    padding: 16px;
    font-size: 14px;
    color: var(--color-text-secondary);

    @media (max-width: $break-small) {
      padding: 8px;
    }

    &:last-child {
      text-align: right;
    }

    &--player {
      color: var(--color-blue-20);
      font-weight: bold;
    }
  }

  &__column {
    border: 1px solid var(--color-grey);

    @media (max-width: $break-normal) {
      border-bottom: none;
    }
  }
}
