.ButtonIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  padding: 8px;
  color: var(--color-white);
  transition: transform ease-in-out $animation-speed, background-color ease-in-out $animation-speed, color ease-in-out $animation-speed;

  &[disabled] {
    cursor: not-allowed;
    opacity: .72;
  }

  &:not([disabled]):hover {
    background-color: var(--color-white-08);
  }

  &:not([disabled]):active {
    transform: scale3d(0.9, 0.9, 1);
    background-color: var(--color-white-16);
  }

  &:not([disabled]):focus {
    background-color: var(--color-white-16);
  }

  i.bi {
    font-size: 18px;
    line-height: 20px;
  }

  &--dark {
    color: var(--color-black-32);

    i.bi {
      font-size: 24px;
      line-height: 24px;
    }

    svg * {
      fill: var(--color-black-32);
    }

    &:not([disabled]):hover {
      background-color: var(--color-black-08);
    }

    &:not([disabled]):active {
      background-color: var(--color-black-16);
    }

    &:not([disabled]):focus {
      background-color: var(--color-black-16);
    }
  }
}
