

.MatchToolbar {
  margin-bottom: 120px;

  @media (max-width: $break-normal) {
    margin-bottom: -40px;
  }

  &__content {
    background: var(--color-white);
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    height: 80px;
    width: 100%;
    position: absolute;
    top: -40px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: $break-normal) {
      flex-direction: column;
      height: auto;
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__inner {
    position: relative;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 100%;
    border-right: 1px solid var(--color-grey);
    padding: 0 24px;
    flex-grow: 1;
    width: 100%;

    @media (max-width: $break-large) {
      padding: 0 16px;
      gap: 8px;
    }

    @media (max-width: $break-normal) {
      width: 100%;
      height: auto;
      border-right: none;
      border-bottom: 1px solid var(--color-grey);
      padding: 8px 16px;
      justify-content: flex-start;
    }

    &:last-child {
      border-right: none;

      @media (max-width: $break-normal) {
        border-bottom: none;
      }
    }

    &Content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &Icon {
      font-size: 20px;
      color: var(--color-text-secondary);

      &--red {
        color: var(--color-red);
      }
    }

    &Title {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-text-secondary);
    }

    &Value {
      font-family: $font-primary;
      font-size: 14px;
      color: var(--color-blue-20);
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      &--red {
        color: var(--color-red);
      }
    }
  }
}
