

.PlayerTransfers {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--color-grey);
    border-bottom: none;
    margin: 24px 0;

    @media (max-width: $break-small) {
      margin: 0;
    }
  }

  &__content {
    overflow-x: auto;
  }

  &__cell {
    padding: 16px;
    font-size: 16px;
    color: var(--color-text-primary);
    border-bottom: 1px solid var(--color-grey);
    text-align: left;
    white-space: nowrap;

    @media (max-width: $break-small) {
      padding: 8px;
      font-size: 14px;
    }

    &--header {
      background: var(--color-black-04);
      color: var(--color-text-secondary);
      font-size: 12px;
      text-transform: uppercase;
    }

    strong,
    span {
      display: block;
      color: var(--color-text-primary);
    }

    small {
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    a {
      color: var(--color-blue-20);
      text-decoration: none;
    }

    &--title strong {
      font-family: $font-primary;
      text-transform: uppercase;
    }
  }

  &__pagination {
    margin: 24px 0;
    text-align: center
  }
}
