

.GroundList {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  margin: 80px 0;

  @media (max-width: $break-medium) {
    margin: 64px 0;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
  }

  @media (max-width: $break-small) {
    margin: 24px 0;
  }
}
