
.Slider {
  margin-top: -64px;
  height: 520px;
  background: var(--color-blue-20);
  margin-bottom: 64px;
  position: relative;

  @media (max-width: $break-small) {
    margin-top: -40px;
    height: 420px;
    margin-bottom: 24px;
  }

  &__container,
  &__content {
    position: relative;
    height: 100%;
  }

  &__content {
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-v] &__slide {
      width: 100%;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }

    .Container {
      height: 100%;
      position: relative;
      display: flex;
    }
  }

  &__image {
    position: absolute;
    top: -24px;
    left: -24px;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    animation: SliderImageFadeIn ease-in-out 10s forwards;

    &--blurred {
      filter: blur(48px);

      @media (max-width: $break-medium) {
        filter: none;
      }
    }

    &Wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(11, 28, 99, .64), rgba(11, 28, 99, .72));
      opacity: .9;
    }
  }

  &__panel {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    background: var(--color-white);
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 24px;

    @media (max-width: $break-small) {
      display: none;
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      height: 40px;
      line-height: 1.46;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-text-secondary);
      position: relative;
      cursor: pointer;
      margin: 0 24px;
      transition: background-color ease-in-out $animation-speed;
      will-change: background-color;
      user-select: none;
      flex-basis: 0;

      &:before {
        content: "";
        display: block;
        width: 1px;
        border-left: 1px solid var(--color-grey);
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 0;
      }

      &:after {
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        background: url("../../../assets/icon-menu-arrow.svg") no-repeat center;
        background-size: 22px 11px;
        width: 22px;
        height: 11px;
        transform: translateX(-50%) translateY(8px);
        top: -34px;
        visibility: hidden;
        opacity: 0;
        transition: transform ease-in-out $animation-speed;
        will-change: transform;
      }

      &:first-child:before {
        display: none;
      }

      &--active {
        color: var(--color-blue-10);

        &:after {
          visibility: visible;
          opacity: 1;
          transform: translateX(-50%) translateY(4px);
        }
      }
    }
  }

  &__hero {
    min-width: 100vw;

    &Inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      max-width: 420px;
      padding-bottom: 40px;
      overflow: hidden;
      width: 100%;

      @media (max-width: $break-small) {
        padding-bottom: 0;
      }
    }

    &Image {
      display: block;
      max-width: 50%;
      max-height: 80%;
      object-fit: contain;
      margin-bottom: 40px;
      border-radius: 8px;
      box-shadow: 5.4px 5.2px 15px 0 var(--color-black-16);

      @media (max-width: $break-medium) {
        display: none;
      }
    }

    &Container {
      justify-content: space-between;
      align-items: center;
      gap: 40px;
    }

    &Title {
      color: var(--color-white);
      font-size: 40px;
      line-height: 48px;
      margin-top: 0;
      text-shadow: 0 8px 10px rgb(0 0 0 / 14%), 0 3px 14px rgb(0 0 0 / 12%), 0 5px 5px rgb(0 0 0 / 20%);

      @media (max-width: $break-small) {
        font-size: 32px;
        line-height: 40px;
      }

      &:before {
        display: none;
      }
    }

    &Message {
      color: var(--color-white);
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-shadow: 0 8px 10px rgb(0 0 0 / 14%), 0 3px 14px rgb(0 0 0 / 12%), 0 5px 5px rgb(0 0 0 / 20%);
    }

    &Button {
      margin-top: 40px;
    }

    &--right .Container {

    }

    &--right &Container {
      flex-direction: row-reverse;
    }

    &--right &Inner {
      align-items: flex-end;
      text-align: right;
    }

    &--right &Title {
      text-align: right;
    }

    &--center .Container {
      justify-content: center;
    }

    &--center &Inner {
      align-items: center;
      text-align: center;
      max-width: 720px;
    }

    &--center &Title {
      text-align: center;
    }
  }
}

@keyframes SliderImageFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(24px, 24px);
  }
  5% {
    opacity: 1;
    visibility: visible;
  }
  to {
    transform: none;
  }
}
