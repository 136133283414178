

.NewsPreviewList {
  margin: 80px 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 24px;
    min-height: 44px;

    @media (max-width: $break-small) {
      margin-bottom: 16px;
    }

    &--withOffset {
      margin-top: 80px;
    }

    h1,
    h2 {
      margin: 0;
    }

    &Link {
      @media (max-width: $break-small) {
        display: none;
      }
    }
  }

  &__link {
    display: none;

    @media (max-width: $break-small) {
      display: block;
      margin-top: 16px;
    }
  }
}