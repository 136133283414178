

.PlayerLastMatches {

  &__item,
  &__header {
    grid-template-columns: 1fr 2fr repeat(5, minmax(0, 1fr));

    div:nth-child(2) {
      text-align: left;
    }
  }

  &__list {
    min-width: 1000px;
  }

  &__item {

    div:first-child {
      font-weight: normal;
    }

    div:nth-child(2) {
      font-weight: bold;
    }
  }

  &__match {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__team {
    display: flex;
    align-items: center;
    gap: 4px;

    &--reverse {
      flex-direction: row-reverse;
    }

    &Brand {
      display: block;
      width: 24px;
      height: 24px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }
}
