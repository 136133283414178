

.MenuDropdown {
  position: relative;

  @media (max-width: $break-small) {
    width: 100%;
  }

  &__button,
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    background: var(--color-blue-overlay-08);
    padding: 10px 16px 8px;
    font-size: 14px;
    line-height: 16px;
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-blue-20);
    border-radius: 4px;
    cursor: pointer;
    gap: 4px;
    user-select: none;
    transition: transform ease-in $animation-speed, box-shadow ease-in $animation-speed;
    will-change: transform, box-shadow;

    @media (max-width: $break-small) {
      width: 100%;
      height: 56px;
    }

    &:active,
    &:active:focus {
      transform: scale3d(0.99, 0.99, 1);
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--color-blue-20);
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 rgb(0 5 85 / 38%);
    overflow: hidden;
    animation: menuDropdownFadeIn ease-in-out;
    transform-origin: 0 0;
    z-index: 1;
  }

  &__item {
    background: none;
    color: var(--color-white);
    width: 100%;
    border-radius: 0;
    white-space: nowrap;

    &:hover {
      background: var(--color-white-16);
    }
  }
}

@keyframes menuDropdownFadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}
