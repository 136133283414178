

.NotFoundPage,
.InternalErrorPage {
  height: 100vh;
  background: var(--color-blue-10);
  color: var(--color-white);

  .Container {
    padding: 0;
  }
}