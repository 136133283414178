

.ClubItem {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--color-grey);
  gap: 16px;
  padding: 24px;
  align-items: center;
  color: var(--color-text-primary);

  @media (max-width: $break-small) {
    padding: 16px;
  }

  &__image {
    display: block;
    width: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: var(--color-grey);

    @media (max-width: $break-small) {
      width: 60%;
    }
  }

  &__content {
    text-align: center;
  }

  &__title {
    margin: 0;
  }
}
