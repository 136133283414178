

.NewsListItem {
  display: flex;
  gap: 24px;
  align-items: center;
  cursor: pointer;
  padding: 24px;
  margin: 0 -24px;
  transition: background-color ease-in-out $animation-speed;
  will-change: background-color;

  @media (max-width: $break-small) {
    gap: 0;
  }

  @media (max-width: $break-xsmall) {
    margin: 0 -16px;
    padding: 16px;
  }

  &:hover {
    background: var(--color-grey);

    @media (max-width: $break-xsmall) {
      background: none;
    }
  }

  &__thumbnail {
    display: block;
    background: var(--color-yellow) url("../../../assets/icon-cross-outline.svg") no-repeat center;
    background-size: 40px 40px;
    min-width: 220px;
    aspect-ratio: 16 / 10;
    position: relative;

    @media (max-width: $break-medium) {
      display: none;
    }

    @supports not (aspect-ratio: auto) {
      min-height: 140px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__union {
    position: absolute;
    background: var(--color-blue-10);
    color: var(--color-white);
    font-family: $font-primary;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    top: 12px;
    right: 0;
    max-width: 100%;
    padding: 6px 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    margin: 0;
  }

  &__subtitle {
    display: flex;
    gap: 6px;
    font-size: 14px;
    color: var(--color-text-secondary);
    text-transform: uppercase;
    font-weight: 500;

    @media (max-width: $break-small) {
      flex-wrap: wrap;
    }

    span {
      display: flex;
      gap: 6px;

      &:before {
        content: "|";
      }

      &:first-child:before {
        display: none;
      }
    }
  }

  &__perex {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);
    flex-grow: 1;
  }
}
