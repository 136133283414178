

.MatchJumbotron {
  position: fixed;
  top: 88px;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  @media (max-width: $break-small) {
    top: 64px;
  }

  &__box {
    position: relative;
    width: 100%;
    max-width: 480px;
    height: 72px;
    padding: 8px 0;
    background: linear-gradient(100deg, var(--color-blue-10) 50%, var(--color-blue-20) 50%);
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    border-radius: 0;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 1fr);
    text-align: center;
    color: var(--color-white);
    grid-column-gap: 24px;
    margin: auto;
    pointer-events: auto;
    z-index: 1;

    @media (max-width: $break-small) {
      grid-column-gap: 8px;
      height: 64px;
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__score {
    font-size: 32px;
    font-family: $font-primary;
    font-weight: bold;
    line-height: 1;

    @media (max-width: $break-small) {
      font-size: 24px;
    }

    &--small {
      font-size: 20px;
      white-space: nowrap;

      @media (max-width: $break-small) {
        font-size: 18px;
      }
    }
  }

  &__status {

    &Inner {
      text-transform: uppercase;
      font-family: $font-primary;
      font-size: 12px;
      padding: 4px 16px;
      color: var(--color-yellow-native);
      background: var(--color-blue-20);
      text-align: center;
      max-width: 480px;
      margin: 0 auto;
      box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
      height: 24px;
    }
  }

  &__live {
    display: flex;
    align-items: center;
    gap: 2px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 8px;
    color: var(--color-yellow);

    .Pulser {
      transform: scale(.5);
    }
  }

  &__timer {
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 14px;
    color: var(--color-yellow);
    text-align: center;

    @media (max-width: $break-small) {
      font-size: 12px;
    }

    &--active {
      animation: timer 2s ease-out;
      animation-iteration-count: infinite;
    }
  }

  &__team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: bold;
    font-size: 16px;

    @media (max-width: $break-small) {
      font-size: 14px;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }

    small {
      color: var(--color-white);
      opacity: .8;
      font-size: 12px;

      @media (max-width: $break-small) {
        font-size: 10px;
      }
    }
  }

  &__event {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 24px);
    opacity: 0;
    display: grid;
    background: var(--color-grey);
    grid-template-columns: 80px minmax(0, 1fr) minmax(0, 1fr);
    color: var(--color-black);
    text-align: left;
    z-index: 1;

    @media (max-width: $break-small) {
      grid-template-columns: 80px auto auto;
    }

    &--goal {
      background: var(--color-green);
      color: var(--color-black);
    }

    &--penalty,
    &--red {
      background: var(--color-red);
      color: var(--color-white);
    }

    &--yellow {
      background: var(--color-yellow);
      color: var(--color-black);
    }

    &--system {
      grid-template-columns: 80px minmax(0, 1fr);
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        line-height: 1;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        background: var(--color-black-04);
      }
    }

    &--yellow &Icon i {
      color: var(--color-yellow-native);
      background: var(--color-black-32);
    }

    &--red &Icon i {
      color: var(--color-red);
      background: var(--color-white-40);
    }

    &Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: right;
      padding-right: 20px;
    }

    &Title {
      display: flex;
      align-items: center;
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
    }

    &Subtitle {
      font-size: 13px;
    }
  }
}

@keyframes timer {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.7;
  }
}
