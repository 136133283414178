

.MatchDashboardList {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    @media (max-width: $break-medium) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column-reverse;
      gap: 16px;
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  &__title,
  &__field {
    margin: 0;

    @media (max-width: $break-small) {
      width: 100%;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: var(--color-black-04);
    border: 1px solid var(--color-grey-10);
    border-bottom: none;
    height: 57px;
  }

  &__round {
    font-family: $font-primary;
    text-transform: uppercase;
    color: var(--color-blue-20);
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
  }

  &__button {
    color: var(--color-blue-20);

    &[disabled] {
      color: var(--color-black-32);
    }
  }

  &__matches .MatchTable {
    margin: 0;
  }

  &__more {
    text-align: center;
    margin: 24px 0;
  }
}
