

.GroundInfo {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  box-shadow: 5.4px 5.2px 15px 0 rgb(155 155 155 / 21%);
  padding: 24px;

  @media (max-width: $break-small) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    padding: 16px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    color: var(--color-text-secondary);
  }

  &__value {
    color: var(--color-text-primary);
    font-weight: bold;
  }
}
