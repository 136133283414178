
.TeamAvatar {
  display: inline-flex;
  width: 24px;
  min-width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background: var(--color-blue-overlay-08);
  color: var(--color-blue-disabled);

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 14px;
    line-height: 14px;
    font-family: $font-primary;
    text-transform: uppercase;
  }
}
