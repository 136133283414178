

.SeasonPlayerStatsTable {
  margin: 40px 0;

  &__table {
    border: 1px solid var(--color-grey);
    border-bottom: none;
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    td,
    th {
      padding: 12px;
      border-bottom: 1px solid var(--color-grey);
      font-size: 14px;
      font-weight: bold;
      line-height: 1;

      a {
        color: var(--color-blue-20);
      }
    }

    th {
      background: var(--color-black-04);
      color: var(--color-text-secondary);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    &--horizontal {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }

  &__avatar {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    background: var(--color-grey-10);
  }

  &__title {

  }

  &__subtitle {
    font-size: 12px;
    color: var(--color-text-secondary);
  }
}
