
.OffenseTable {
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }
}

.OffenseItem {
  padding: 16px;
  background-color: var(--color-black-04);
  border: 2px solid transparent;
  border-radius: 4px;
  color: var(--color-text-primary);
  align-items: center;
  display: grid;
  grid-template-columns: 120px repeat(2, minmax(0, 1fr));
  gap: 40px;



  &__photo {
    aspect-ratio: 1;
    border-radius: 16px;
    overflow: hidden;

    div {
      width: 100%;
      height: 100%;
      background-color: var(--color-black-04);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 32px;
      color: var(--color-black-32);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: $break-small) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
  }

  &:hover {
    border-color: var(--color-blue-overlay-08);
    cursor: pointer;
  }

  &__type {
    margin: 0;
  }

  &__date {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text-secondary);
  }

  &__description {
    font-size: 14px;
    color: var(--color-text-secondary);
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
    margin-top: 16px;
    text-align: right;

    @media (max-width: $break-small) {
      text-align: left;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 2px;

    label {
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    strong {
      font-size: 16px;
    }

    &--long {

      strong {
        font-size: 14px !important;
      }
    }
  }
}


.OffenseHeader {

  &__map {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-content: center;
    background: #232323;
    padding: 40px;
    gap: 40px;
    color: var(--color-white);
    height: 400px;

    @media (max-width: $break-medium) {
      height: auto;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    margin-top: 24px;

    @media (max-width: $break-medium) {
      width: 100%;
      text-align: center;
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    label {
      font-size: 14px;
      color: var(--color-white-40)
    }

    strong {
      font-size: 16px;
    }
  }

  &__person {
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: $break-medium) {
      flex-direction: column;
      gap: 0;
    }
  }
}


.OffenseEventItem {
  margin-bottom: 40px;
  padding: 64px;
  position: relative;

  @media (max-width: $break-small) {
    padding: 16px;
  }

  &:before {
    content: "";
    display: block;
    border-left: 1px dashed var(--color-grey-20);
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;

    @media (max-width: $break-medium) {
      left: 73px;
    }

    @media (max-width: $break-small) {
      left: 34px;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 64px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:before,
  &:after {
    pointer-events: none;
  }
}
