

.MatchRoster {
  margin: 80px 0;

  &__title {
    margin-bottom: 0;

    &--center {
      text-align: center;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 96px;
  }

  &__panel {
    flex-grow: 1;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &Title {
      margin: 0;
      padding: 0 16px;
      font-size: 20px;
      color: var(--color-blue-20);

      small {
        color: var(--color-black-32)
      }
    }

    &Section {
      padding-top: 16px;
    }

    &Item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px 16px;

      &Title {
        font-size: 14px;
        line-height: 1;
        font-weight: bold;
        color: var(--color-text-secondary);
      }

      &Value {
        color: var(--color-text-primary);
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
      }
    }
  }

  &__ground {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 64px;
    position: relative;

    svg {
      display: block;
      width: 100%;
      height: auto;
      transform: perspective(40em) rotateX(18deg);
      box-shadow: rgba(22, 31, 39, 0.42) 0 60px 123px -25px, rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
      border: 2px solid var(--color-white);
      overflow: hidden;
      border-radius: 24px;
    }
  }

  &__home,
  &__visiting {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  &__visiting {
    left: auto;
    right: 0;
  }

  &__player {
    --image-size: 96px;

    position: absolute;
    background: var(--color-white);
    border-radius: 8px;
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 96px;
    padding-bottom: 8px;
    transform: translate(-50%, -50%);

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingRosterPlayer ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }

    &Photo {
      width: 100%;
      height: var(--image-size);
      margin-bottom: 8px;
      user-select: none;
      position: relative;

      img,
      div {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        background: var(--color-text-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-family: $font-primary;
        text-transform: uppercase;
        color: var(--color-black-32);
        font-weight: 900;
        user-select: none;
        transition: box-shadow ease-in-out $animation-speed;
        will-change: box-shadow;
      }
    }

    &Name {
      text-transform: uppercase;
      color: var(--color-text-primary);
      font-weight: bold;
      padding: 0 8px;
      font-size: 14px;
      line-height: 16px;
    }

    &Number {
      color: var(--color-white);
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      font-family: $font-primary;
      font-size: 24px;
      padding: 8px;
      line-height: 1;
      font-weight: bold;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
    }
  }

  &__home &__player:nth-child(1) {
    top: 50%;
    left: 56px;
  }

  &__home &__player:nth-child(2) {
    top: 116px;
    left: 140px;
  }

  &__home &__player:nth-child(3) {
    bottom: -60px;
    left: 100px;
  }

  &__home &__player:nth-child(4) {
    top: 116px;
    left: 300px;
  }

  &__home &__player:nth-child(5) {
    bottom: -60px;
    left: 280px;
  }

  &__home &__player:nth-child(6) {
    top: 50%;
    left: 240px;
  }

  &__visiting &__player:nth-child(1) {
    top: 50%;
    right: -44px;
  }

  &__visiting &__player:nth-child(2) {
    top: 116px;
    right: 46px;
  }

  &__visiting &__player:nth-child(3) {
    bottom: -60px;
    right: 4px;
  }

  &__visiting &__player:nth-child(4) {
    top: 116px;
    right: 208px;
  }

  &__visiting &__player:nth-child(5) {
    bottom: -60px;
    right: 180px;
  }

  &__visiting &__player:nth-child(6) {
    top: 50%;
    right: 148px;
  }
}


@keyframes floatingRosterPlayer {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -50%) translateY(8px);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
