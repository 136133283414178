
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-native;
  font-weight: 400;
  quotes: "“" "”";
}

* {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

h1, h2, h3, h4, h5, h6, button {
  font-family: $font-primary;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}


h1, h2, h3, h4, h5, h6 {
  color: var(--color-blue-10);
}

a {
  text-decoration: none;
}

h1,
.h1 {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: left;
  margin: 40px 0 24px;

  @media (max-width: $break-small) {
    font-size: 28px;
    line-height: 32px;
  }

  &:before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url("~@amf/shared/assets/icon-cross-green.svg") no-repeat center;
    background-size: 24px 24px;
    min-width: 24px;
    margin-top: 8px;

    @media (max-width: $break-small) {
      margin-top: 4px;
    }
  }
}

h2, h3, h4 {
  margin: 24px 0 8px;
}