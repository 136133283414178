

.LeagueMatchSlider {
  display: block;
  margin: 40px 0;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 4px;
    width: 56px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 70%);
    background-attachment: local;
    pointer-events: none;
    right: 0;
  }

  &__wrapper {
    overflow: scroll;
    @include scrollbar(auto, 4px, var(--color-grey), var(--color-blue-20));
    padding-bottom: 8px;
  }

  &__item {
    min-width: 320px;
    height: 100%;
    margin-right: 24px;
    border: 1px solid var(--color-grey);
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--now {
      background: var(--color-yellow-overlay-08);
      border-color: var(--color-yellow);
    }
  }

  &__scroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }

  &__teams {
    line-height: 20px;
    text-transform: uppercase;
    color: var(--color-text-secondary);
    font-family: $font-primary;
    font-weight: 400;
    font-size: 14px;

    strong {
      color: var(--color-blue-20);
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__date {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: $font-primary;
    text-transform: uppercase;
    color: var(--color-text-secondary);
  }

  &__score {
    font-size: 20px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font-primary;
    color: var(--color-blue-20);
  }

  &__match {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 2px;
  }
}
