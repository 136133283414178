

.TeamComparison {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: $break-small) {
    margin: 40px 0;
    gap: 16px;
  }

  &__title {
    margin: 0;

    &--center {
      text-align: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    color: var(--color-text-primary);

    @media (max-width: $break-small) {
      gap: 8px;
    }
  }

  &__item,
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 220px 1fr 1fr;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media (max-width: $break-medium) {
      grid-template-columns: 1fr 220px 1fr;
    }

    @media (max-width: $break-small) {
      grid-template-columns: 1fr 80px 1fr;
    }

    &Title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  &__header {

    @media (max-width: $break-medium) {
      div:first-child,
      div:last-child {
        display: none;
      }
    }

    &Team {
      font-size: 16px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-blue-20);
      font-weight: bold;

      @media (max-width: $break-medium) {
        text-align: center;
      }

      @media (max-width: $break-small) {
        overflow: hidden;
        font-size: 12px;
      }

      &--left {
        text-align: right;

        @media (max-width: $break-medium) {
          text-align: center;
        }
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: $font-primary;
    text-transform: uppercase;

    &--left {
      justify-content: flex-end;

      @media (max-width: $break-medium) {
        justify-content: center;
      }
    }

    &--right {
      justify-content: flex-start;

      @media (max-width: $break-medium) {
        justify-content: center;
      }
    }
  }

  &__progress {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: var(--color-blue-overlay-04);
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    @media (max-width: $break-medium) {
      display: none;
    }

    &Thumb {
      height: 100%;
      position: absolute;
      top: 0;
      background: var(--color-yellow);
      border-radius: 4px;
    }

    &--right &Thumb {
      right: 0;
    }

    &--left &Thumb {
      left: 0;
    }
  }
}
