
.Content {
  margin-bottom: 80px;

  @media (max-width: $break-small) {
    margin-bottom: 40px;
  }

  h2, h3, h4 {
    margin: 40px 0 8px;
  }

  p {
    display: block;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: var(--color-text-primary);
    margin: 24px 0;

    a {
      color: var(--color-blue-10);
      text-decoration: underline;
      font-weight: bold;
    }
  }

  ul,
  ol {
    padding-left: 24px;
    list-style-position: outside;
  }

  ul {
    list-style-image: url("../../../assets/icon-cross-green.svg");
  }

  li {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: var(--color-text-primary);
    margin: 16px 0;
    padding-left: 8px;

    a {
      color: var(--color-blue-10);
      text-decoration: underline;
      font-weight: bold;
    }
  }

  h2 + p, h3 + p, h4 + p {
    margin-top: 8px;
  }

  &__image {
    margin: 24px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &--withBorder {
      border: 1px solid var(--color-grey-10);
    }

    img {
      display: block;
      width: 100%;
    }

    &--withBackground {
      background-color: var(--color-grey);
      height: 640px;

      @media (max-width: $break-small) {
        height: 256px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &Caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
      padding: 24px;
      font-family: $font-primary;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: var(--color-white);
      opacity: 0;
      visibility: hidden;
      transition: opacity ease-in-out $animation-speed, transform ease-in-out $animation-speed;
      will-change: opacity, transform;
      transform: translateY(8px);
      pointer-events: none;
    }

    &:hover &Caption {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }

  &__gallery {
    margin: 24px 0;

    & > div {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 24px;

      @media (max-width: $break-medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (max-width: $break-small) {
        grid-gap: 16px;
      }
    }

    &--two > div {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__gallery &__image {
    margin: 0;

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__link {
    display: flex;
    background: var(--color-grey);
    border: 1px solid var(--color-grey-10);
    padding: 24px;
    max-width: 620px;
    margin: 40px auto;
    gap: 24px;
    transition: box-shadow ease-in-out $animation-speed;
    will-change: box-shadow;

    @media (max-width: $break-xsmall) {
      flex-direction: column;
    }

    &:hover {
      box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    }

    &Content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &Title {
        font-family: $font-primary;
        text-transform: uppercase;
        color: var(--color-blue-10);
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
      }

      &Description {
        font-size: 14px;
        line-height: 18px;
        color: var(--color-text-secondary);
      }
    }

    &Image {
      display: block;
      flex-grow: 1;

      img {
        display: block;
        height: 146px;
        width: 146px;
        object-fit: cover;
        object-position: center;

        @media (max-width: $break-xsmall) {
          width: 100%;
          height: 100%;
          max-height: 200px;
        }
      }
    }
  }

  hr {
    display: block;
    background: url("../../../assets/icon-cross-green.svg") no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    border: none;
    margin: 40px auto;
  }

  &__alert {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: var(--color-blue-10);

    @media (max-width: $break-xsmall) {
      padding: 16px;
    }

    &Title {
      font-size: 16px;
      line-height: 24px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-white);
    }

    &Message {
      margin: 0 !important;
      color: var(--color-white) !important;

      * {
        color: var(--color-white) !important;
      }

      a {
        font-weight: bold;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--color-grey-20);
    border-bottom: none;

    tr {

      td {
        padding: 8px 16px;
        border-right: 1px solid var(--color-grey-20);
        border-bottom: 1px solid var(--color-grey-20);
        background: var(--color-grey);
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: var(--color-text-primary);

        @media (max-width: $break-xsmall) {
          padding: 4px 8px;
        }

        &:last-child {
          border-right: none;
        }
      }

      &:nth-child(2n + 1) td {
        background: var(--color-grey-10);
      }
    }
  }

  &__attachment {
    display: flex;
    background: var(--color-grey);
    border: 1px solid var(--color-grey-10);
    padding: 24px;
    margin: 24px auto;
    transition: box-shadow ease-in-out $animation-speed;
    will-change: box-shadow;
    gap: 24px;
    align-items: center;

    @media (max-width: $break-xsmall) {
      padding: 16px;
      margin: 24px auto;
    }

    &:hover {
      box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    }

    &Icon {
      font-size: 24px;
      line-height: 24px;
      color: var(--color-blue-10);

      @media (max-width: $break-xsmall) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &Content {
      flex-grow: 1;
    }

    &Title {
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-blue-10);
      font-size: 16px;
      line-height: 20px;

      @media (max-width: $break-xsmall) {
        font-size: 14px;
      }
    }

    &Size {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-text-secondary);

      @media (max-width: $break-xsmall) {
        font-size: 12px;
      }
    }
  }
  &__attachment + &__attachment {
    margin-top: -16px;

    @media (max-width: $break-xsmall) {
      margin-top: -16px;
    }
  }

  &__embed {
    margin: 40px 0;

    @media (max-width: $break-xsmall) {
      margin: 24px 0;
    }

    iframe {
      width: 100%;
    }

    &--youtube {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }

    &--instagram {
      display: block;
      max-width: 420px;
      margin: auto;
    }

    &--twitter {
      display: block;
      max-width: 600px;
      margin: auto;
    }
  }

  &__raw {
    margin: 40px 0;

    @media (max-width: $break-xsmall) {
      margin: 24px 0;
    }

    iframe {
      width: 100%;
    }
  }
}

p.SRLCaptionText {
  font-family: $font-primary !important;
}
