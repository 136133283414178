

.ContactPage {

  &__content {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    align-items: flex-start;

    @media (max-width: $break-large) {
      display: block;
    }
  }

  &__form {
    padding: 24px;
    background: var(--color-grey);
    flex-basis: 66%;
    flex-grow: 1;
    min-height: 256px;

    @media (max-width: $break-large) {
      margin-bottom: 40px;
    }

    @media (max-width: $break-small) {
      padding: 16px;
    }

    &--center {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &Row {
      display: flex;
      gap: 24px;

      @media (max-width: $break-medium) {
        display: block;
      }
    }

    &Col {
      flex-grow: 1;
    }

    &Field {
      width: 100%;
    }

    .FormField__input:not(.FormField--loading .FormField__input):focus {
      background: rgb(245 254 248);
    }

    &Message {
      color: var(--color-text-secondary);
      max-width: 320px;
      line-height: 20px;
    }
  }

  &__info {
    flex-grow: 1;

    @media (max-width: $break-large) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 40px;
    }

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &Panel {
      display: block;
      margin-bottom: 24px;

      @media (max-width: $break-medium) {
        margin: 0;
      }

      h2 {
        margin: 0;
      }

      p,
      address {
        font-style: normal;
        line-height: 24px;
        margin: 0;
        color: var(--color-text-secondary);
      }

      a {
        color: var(--color-blue);
        font-weight: bold;
      }
    }
  }

  &__person {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;

    &List {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px;

      @media (max-width: $break-medium) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    &Photo {
      background: no-repeat center var(--color-grey);
      background-size: cover;
      width: 128px;
      height: 128px;
      min-width: 128px;
      border-radius: 4px;

      @media (max-width: $break-small) {
        width: 64px;
        height: 64px;
        min-width: 64px;
      }
    }

    &Info {

      h3 {
        margin: 0;
      }

      p {
        font-style: normal;
        line-height: 24px;
        margin: 0;
        color: var(--color-text-secondary);
      }

      a {
        color: var(--color-blue);
        font-weight: bold;
      }
    }
  }
}
