
.ClubList {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  margin: 80px 0;

  @media (max-width: $break-medium) {
    margin: 64px 0;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: $break-small) {
    margin: 40px 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
