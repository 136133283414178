
.ContactPage {

  &__personPhoto {
    &--janPinkava {
      background-image: url("../../assets/jan-pinkava.jpeg");
    }
    &--michalSik {
      background-image: url("../../assets/michal-sik.jpeg");
    }
  }

  &__infoPanel a,
  &__personInfo a {
    color: var(--color-blue-10);
  }
}
