
.Pulser {
  position: relative;
  width: 12px;
  height: 12px;

  &__ring {
    border: 3px solid var(--color-yellow);
    border-radius: 30px;
    height: 24px;
    width: 24px;
    position: absolute;
    left: -6px;
    top: -6px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0
  }

  &__circle {
    width: 12px;
    height: 12px;
    background-color: var(--color-yellow);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}
