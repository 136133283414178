

.LeagueTableDashboardGrid {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    @media (max-width: $break-medium) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $break-small) {
      flex-direction: column-reverse;
      gap: 16px;
      width: 100%;

      a {
        width: 100%;
      }
    }
  }

  &__title,
  &__field {
    margin: 0;

    @media (max-width: $break-small) {
      width: 100%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    margin: 24px 0;

    &--1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &--3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 24px;
      margin: 24px 0;
    }

    @media (max-width: $break-small) {
      gap: 16px;
      margin: 16px 0;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-grey);

    &Header {
      display: flex;
      width: 100%;
      min-height: 56px;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      &Name {
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: bold;
        gap: 8px;
        line-height: 1;
      }

      &Type {
        color: var(--color-text-secondary);
        font-family: $font-primary;
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    &List {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &Team {
      display: flex;
      align-items: center;
      padding: 0 16px 0 8px;
      height: 48px;
      color: var(--color-text-primary);
      gap: 8px;
      position: relative;
      overflow: hidden;
      border-top: 1px solid var(--color-black-04);

      &--lastAdvancing {
        border-bottom: 2px solid var(--color-yellow);
      }

      &--firstDescending {
        border-top: 2px solid var(--color-red);
      }

      &--advancing {
        /*background: var(--color-yellow-overlay-16);

        &:nth-child(2n + 1) {
          background: var(--color-yellow-overlay-08);
        }*/
      }

      &--descending {
        /*background: var(--color-red-overlay-16);

        &:nth-child(2n + 1) {
          background: var(--color-red-overlay-08);
        }*/
      }

      &--champion,
      &--champion:nth-child(2n + 1) {
        border: 2px solid var(--color-yellow);
        background: var(--color-yellow-overlay-08);
        margin: 0 -1px;
      }

      &--green {
        background: #dcfce7;
      }

      &--yellow {
        background: #fefce8;
      }

      &--orange {
        background: #fff7ed;
      }

      &--red {
        background: #fef2f2;
      }

      &--gray {
        background: #f9fafb;
      }
    }

    &Number {
      font-weight: bold;
      font-family: $font-primary;
      font-size: 14px;
      width: 32px;
      text-align: center;

      &--yellow {
        color: var(--color-yellow-10);
        font-size: 20px;
        position: relative;
        left: -4px;
      }

      &--fit {
        width: 24px;
      }
    }

    &Name {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      font-size: 14px;
    }

    &Info {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: var(--color-text-secondary);
      line-height: 1;
      font-weight: bold;
      font-family: $font-primary;
    }

    &Retributions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 16px 18px;
      background: var(--color-blue-overlay-04);
      font-size: 12px;
      color: var(--color-text-secondary);

      p {
        display: flex;
        align-items: flex-start;
        line-height: 1.24;
        gap: 6px;
      }
    }
  }

  &__content--loading &__tableHeaderName,
  &__content--loading &__tableHeaderType,
  &__content--loading &__tableNumber,
  &__content--loading &__tableName div,
  &__content--loading &__tableInfo {
    @include shimmer(3s, 512px, var(--color-grey), var(--color-grey-10), padding-box);
    border-radius: 6px;
  }

  &__content--loading &__tableHeaderName,
  &__content--loading &__tableHeaderType {
    height: 18px;
  }

  &__content--loading &__tableHeaderName {
    width: 40%;
  }

  &__content--loading &__tableHeaderType {
    width: 10%;
  }

  &__content--loading &__tableNumber {
    height: 20px;
    width: 16px;
    margin: 0 8px;
  }

  &__content--loading &__tableName div {
    height: 20px;
    width: 50%;
  }

  &__content--loading &__tableInfo {
    height: 20px;
    width: 20px;
  }

  &__more {
    text-align: center;
  }

  &__cross {
    background: url("../../../assets/icon-cross-yellow.svg") no-repeat center;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    position: absolute;
    opacity: .24;

    &:nth-child(1) {
      right: 16%;
      top: 20px;
    }

    &:nth-child(2) {
      left: 60%;
      bottom: 20px;
    }

    &:nth-child(3) {
      right: 70%;
      top: 20px;
    }

    &:nth-child(4) {
      right: 55%;
      top: 4px;
    }

    &:nth-child(5) {
      right: 4%;
      bottom: 20px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }
  }
}
