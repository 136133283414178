

.MatchRosterList {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  &__title {
    margin-bottom: 0;

    &--center {
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    margin: 40px 0;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 24px;
      margin: 24px 0;
    }

    @media (max-width: $break-small) {
      gap: 16px;
      margin: 16px 0;
    }
  }

  &__roster {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-grey);
    border-radius: 8px;
    box-shadow: 5.4px 5.2px 15px 0 rgb(155 155 155 / 21%);

    &--home {

    }

    &--visiting {

    }

    &--empty {
      padding: 16px;
      text-align: center;
      color: var(--color-text-secondary);
    }
  }

  &__team {
    display: flex;
    width: 100%;
    min-height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    &Name {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      line-height: 1;
    }

    &Brand {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--color-blue-overlay-08);
    }

    &Type {
      color: var(--color-text-secondary);
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__player {
    display: flex;
    align-items: center;
    padding: 16px;
    color: var(--color-text-primary);
    gap: 8px;

    &:nth-child(2n + 1) {
      background: var(--color-blue-overlay-04);
    }

    &:hover {
      background: var(--color-blue-overlay-08);
    }

    &Number {
      font-weight: bold;
      font-family: $font-primary;
      font-size: 14px;
      min-width: 24px;
    }

    &Avatar {
      background: var(--color-blue-overlay-08);
      width: 32px;
      height: 32px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 24px;
      margin: -4px 0;
      object-fit: cover;
      object-position: center;
    }

    &Name {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      font-size: 14px;
    }

    &Badge {
      background: var(--color-blue-20);
      color: var(--color-white);
      font-size: 14px;
      padding: 2px 4px;
      border-radius: 4px;
      min-width: 20px;
      text-align: center;

      &--green {
        background: var(--color-green);
        color: var(--color-blue-20);
      }
    }

    &Info {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: var(--color-text-secondary);
      line-height: 1;
      font-weight: bold;

      &--red {
        color: var(--color-red);
      }

      &--yellow {
        color: var(--color-yellow-native);
      }

      &--redYellow {
        display: inline-block;
        width: 12px;
        height: 16px;
        background: linear-gradient(128deg, var(--color-red) 50%, var(--color-yellow-native) 50%);
        border-radius: 3px;
        margin: 0 2px;

        &--small {
          width: 10px;
          height: 14px;
        }
      }
    }

    &Chevron {
      color: var(--color-grey-20);
    }
  }

  &__empty {
    padding: 16px;
    color: var(--color-text-secondary);
  }
}
