

.Menu {
  border-bottom: 1px solid var(--color-grey);
  padding: 24px 0;
  position: sticky;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  top: 88px;
  z-index: 10;

  @media (max-width: $break-small) {
    position: static;
    padding: 16px 0;
  }

  &--scrollable {
    padding: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    width: 28px;
    height: 28px;
    z-index: 20;
    background: var(--color-white);
    border-radius: 16px;
    box-shadow: 0 0 8px 0 rgb(0 5 85 / 38%);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-60%);

    @media (max-width: $break-small) {
      display: none;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--scrollable &__wrapper {
    @include scrollbar(auto, 4px, var(--color-grey), var(--color-black-16));
    padding: 24px 32px;
    margin: 0 -32px;
    overflow-x: auto;

    @media (max-width: $break-small) {
      padding: 16px 0;
      margin: 0;
      width: 100%;
    }
  }

  &--scrollable &__container {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 4px;
      width: 32px;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 70%);
      background-attachment: local;
      pointer-events: none;
      right: 0;

      @media (max-width: $break-small) {
        display: none;
      }
    }

    &--last:after {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 4px;
      width: 32px;
      background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      background-attachment: local;
      pointer-events: none;
      left: 0;

      @media (max-width: $break-small) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &--scrollable &__content {
    flex-wrap: nowrap;
  }

  &__item {
    display: flex;
    background: var(--color-blue-overlay-08);
    padding: 10px 16px 8px;
    font-size: 14px;
    line-height: 16px;
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-blue-20);
    border-radius: 4px;
    appearance: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    @media (max-width: $break-small) {
      width: 100%;
      justify-content: center;
      text-align: center;
      height: 56px;
      align-items: center;
    }

    &--active {
      background: var(--color-blue-20);
      color: var(--color-white);
      box-shadow: 0 2px 16px 0 rgb(0 5 85 / 38%);
    }
  }

  &__children {
    @media (max-width: $break-small) {
      width: 100%;
    }
  }
}
