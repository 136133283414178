
.LeagueAdvancedStats {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;

  h2 {
    margin: 0;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__cards {
    background: var(--color-blue-overlay-04);
    border-radius: 8px;
    padding: 32px 24px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
    position: relative;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__table {

    &Row {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      color: var(--color-text-primary);
      border-bottom: 1px solid var(--color-grey);
      padding: 12px 0;
      font-size: 14px;
      border-radius: 4px;

      @media (max-width: $break-small) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      &--padding {
        padding: 12px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: var(--color-blue-overlay-04);
      }

      &Label {
        display: flex;
        align-items: center;
      }

      &Name {
        font-weight: bold;
        text-align: right;

        @media (max-width: $break-small) {
          text-align: left;
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--color-text-primary);
    gap: 16px;
    position: relative;
    z-index: 1;
    padding: 16px 0;
    transition: all ease-in-out $animation-speed;
    border-radius: 8px;

    &:hover {
      background: var(--color-blue-overlay-04);
    }

    &Title {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-text-secondary);
    }

    &Photo {
      width: 128px;
      height: 128px;
      background: var(--color-white-40);
      box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
      position: relative;
      border-radius: 16px;

      @media (max-width: $break-small) {
        width: 80px;
        height: 80px;
      }

      img,
      div {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        background: var(--color-blue-overlay-08);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 64px;
        font-family: $font-primary;
        text-transform: uppercase;
        color: var(--color-blue-20);
        font-weight: 900;
        user-select: none;
        transition: box-shadow ease-in-out $animation-speed;
        will-change: box-shadow;
        border-radius: 16px;
      }
    }

    &Team {
      position: absolute;
      top: -16px;
      right: -16px;
      border-radius: 50% !important;
      overflow: hidden;
      width: 56px !important;
      height: 56px !important;
      padding: 8px;
      background: var(--color-white) !important;

      @media (max-width: $break-small) {
        width: 48px !important;
        height: 48px !important;
        top: -24px;
        right: -24px;
      }

      img {
        border-radius: 50%;
      }

      div {
        font-size: 16px;
        border-radius: 50%;

        @media (max-width: $break-small) {
          font-size: 12px;
        }
      }
    }

    &Name {
      font-size: 20px;
      line-height: 1;
      text-transform: uppercase;
      font-family: $font-primary;
    }

    &TeamName {
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      font-family: $font-primary;
      color: var(--color-text-secondary);
      margin-bottom: 8px;
    }

    &Value {
      font-size: 20px;
      line-height: 1;
      font-family: $font-primary;
      text-transform: uppercase;
      margin-top: 8px;

      @media (max-width: $break-small) {
        font-size: 16px;
      }
    }

    &More {
      margin-top: 8px;
    }

    &Content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
