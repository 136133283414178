

.Grid {
  display: grid;
  gap: 24px;

  @media (max-width: $break-small) {
    grid-template-columns: minmax(0, 1fr) !important;
  }
}
