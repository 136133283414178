

.Tabs {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--color-blue-overlay-08);
  height: 36px;
  border-radius: 18px;
  padding: 2px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    font-family: $font-primary;
    color: var(--color-blue-20);
    border-radius: 16px;
    position: relative;
    user-select: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      bottom: 8px;
      border-left: 1px solid var(--color-blue-overlay-16);
    }

    &:first-child:before {
      display: none;
    }

    &--selected {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
      background: var(--color-white);

      &:before {
        display: none;
      }
    }

    &--selected + &:before {
      display: none;
    }
  }
}
