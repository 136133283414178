

.PersonHeader {
  position: relative;
  z-index: 11;
  background: var(--color-yellow);
  margin: -64px 0 128px;
  padding: 64px 0 0;

  @media (max-width: $break-small) {
    margin: -40px 0 80px;
    padding-top: 16px;
  }

  &--bottomFit {
    margin-bottom: 64px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: $break-small) {
      flex-direction: column-reverse;

      a {
        width: 100%;
      }
    }
  }

  &__title {
    margin: 0;

    @media (max-width: $break-small) {
      width: 100%;
    }

    &:before {
      background: url("~@amf/shared/assets/icon-cross-blue.svg") no-repeat center;
      background-size: 24px 24px;
    }
  }

  &__card {
    color: var(--color-white);
    background: var(--color-blue-20);
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    border-radius: 8px;
    position: relative;
    z-index: 1;
    padding: 40px;
    top: 64px;
    margin-top: -64px;
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: $break-medium) {
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    @media (max-width: $break-small) {
      margin-left: -16px;
      margin-right: -16px;
      border-radius: 0;
      padding: 24px 16px;
    }
  }

  &__photo {
    width: 256px;
    min-width: 256px;
    height: 256px;
    background: var(--color-white-40);
    border-radius: 16px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
    position: relative;
    z-index: 1;

    @media (max-width: $break-medium) {
      width: 128px;
      height: 128px;
      min-width: 128px;
    }

    img,
    div {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      background: var(--color-blue-overlay-08);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      font-family: $font-primary;
      text-transform: uppercase;
      color: var(--color-blue-20);
      font-weight: 900;
      user-select: none;
      transition: box-shadow ease-in-out $animation-speed;
      will-change: box-shadow;
      border-radius: 16px;

      @media (max-width: $break-medium) {
        font-size: 40px;
      }
    }
  }

  &__dress {
    position: absolute;
    bottom: -16px;
    right: -16px;
    width: 80px !important;
    height: 80px !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);

    @media (max-width: $break-medium) {
      width: 64px !important;
      height: 64px !important;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    &--light {
      background: var(--color-white) !important;
    }

    &--dark {
      background: var(--color-blue-20) !important;
      border: 3px solid var(--color-white);
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @media (max-width: $break-medium) {
      width: 100%;
      align-items: center;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 16px;
      background: var(--color-white-08);
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--color-white-16);
      margin-top: 24px;

      @media (max-width: $break-large) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (max-width: $break-medium) {
        width: 100%;
      }

      @media (max-width: $break-small) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      li {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 4px;

        label {
          color: var(--color-white-40);
        }

        strong,
        a {
          font-family: $font-primary;
          font-weight: bold;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        img,
        svg {
          display: block !important;
          width: 24px !important;
          height: 24px !important;
          border-radius: 50% !important;
          background: var(--color-white);
          object-fit: cover;
        }

        div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 24px;

          @media (max-width: $break-large) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
          }

          a {
            display: flex;
            align-items: flex-start;
          }
        }
      }
    }

    a {
      color: currentColor;
    }
  }

  &__fullName {
    color: currentColor;
    font-size: 32px;
    line-height: 1;

    @media (max-width: $break-small) {
      text-align: center;
    }
  }

  &__identifier {
    width: fit-content;
    background: var(--color-white-08);
    border: 1px solid var(--color-white-16);
    padding: 6px;
    border-radius: 4px;
    font-weight: bold;
  }

  &__crosses {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $break-small) {
      display: none;
    }
  }

  &__cross {
    background: url("../../../assets/icon-cross-outline.svg") no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: .40;

    &:nth-child(1) {
      right: 16%;
      top: 20px;
    }

    &:nth-child(2) {
      left: 25%;
      bottom: 40px;
    }

    &:nth-child(3) {
      right: 85%;
      top: 40px;
    }

    &:nth-child(4) {
      right: 25%;
      top: 120px;
    }

    &:nth-child(5) {
      right: 4%;
      bottom: 40px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 8s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }
  }
}
