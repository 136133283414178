

.DocumentsPage {

  p {
    color: var(--color-text-secondary);
    line-height: 20px;
    margin-bottom: 40px;
  }

  &__document {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 1px solid var(--color-grey);
    padding: 24px;

    @media (max-width: $break-small) {
      padding: 16px;
    }

    h3 {
      margin: 0;
    }

    p {
      color: var(--color-text-secondary);
      margin-bottom: 16px;
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      font-weight: bold;
      color: var(--color-blue);
    }

    &List {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px;
      margin-bottom: 40px;

      @media (max-width: $break-medium) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}