
.TeamRoster {
  margin: 80px 0;

  @media (max-width: $break-small) {
    margin: 40px 0;
  }

  h2 {
    margin: 0;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 16px;
    }

    .FormField {
      margin: 0;

      @media (max-width: $break-small) {
        width: 100%;
      }
    }

    form {
      display: flex;
      align-items: center;
      gap: 24px;

      @media (max-width: $break-small) {
        width: 100%;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  &__content {
    overflow-x: auto;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid var(--color-grey);
    border-bottom: none;
    margin: 16px 0;
  }

  &__section {
    border-bottom: 2px solid var(--color-grey);
  }

  &__header {
    background: var(--color-black-04);
    color: var(--color-text-secondary);
    font-size: 12px;
    text-transform: uppercase;
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid var(--color-grey);

    @media (max-width: $break-small) {
      padding: 8px;
      white-space: nowrap;
    }

    &--center {
      text-align: center
    }
  }

  &__cell {
    padding: 16px;
    font-size: 16px;
    color: var(--color-text-primary);
    border-bottom: 1px solid var(--color-grey);
    text-align: left;
    white-space: nowrap;

    @media (max-width: $break-small) {
      padding: 8px;
      font-size: 14px;
    }

    &Title {
      display: block;
      white-space: nowrap;
      color: var(--color-blue-20);
    }

    &Subtitle {
      font-size: 14px;
      color: var(--color-text-secondary);
      white-space: nowrap;
    }

    &--avatar {
      width: 56px;
      padding-right: 0;
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }
  }

  &__avatar {
    display: block;
    width: 40px;
    height: 40px;
    background: var(--color-grey);
    border-radius: 50%;
    object-fit: cover;
    object-position: center;

    @media (max-width: $break-small) {
      width: 24px;
      height: 24px;
    }
  }

  tbody tr:hover &__cell {
    background: var(--color-blue-overlay-04);
  }
}
