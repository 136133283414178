

.Header {
  background: var(--color-white);
  margin-top: -64px;
  position: relative;
  border-bottom: 1px solid var(--color-grey);
  overflow: hidden;

  @media (max-width: $break-small) {
    margin-top: -40px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    position: relative;

    @media (max-width: $break-small) {
      flex-direction: column;
      gap: 24px;
      padding: 40px 0 16px;
    }

    .FormField {
      margin: 0;

      @media (max-width: $break-small) {
        width: 100%;
      }
    }
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__overlay {
    backdrop-filter: blur(16px);
    background: rgba(255, 255, 255, .92);
  }

  &__title {
    margin: 0;

    &:before {
      display: none;
    }
  }

  &__title,
  &__children {
    position: relative;
    z-index: 1;

    @media (max-width: $break-small) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }

  &__crosses {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .1;
  }

  &__cross {
    background: url("../../../assets/icon-cross-disabled.svg") no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    position: absolute;

    &:nth-child(1) {
      right: 16%;
      top: 20px;
    }

    &:nth-child(2) {
      left: 25%;
      bottom: 40px;
    }

    &:nth-child(3) {
      right: 85%;
      top: 40px;
    }

    &:nth-child(4) {
      right: 55%;
      top: 40px;
    }

    &:nth-child(5) {
      right: 4%;
      bottom: 40px;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }
  }
}
