

.LeagueListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  cursor: pointer;
  background: var(--color-blue-20);
  color: var(--color-white);
  text-align: center;
  transition: box-shadow ease-in-out $animation-speed;
  will-change: box-shadow;

  &:hover {
    box-shadow: 5.4px 5.2px 15px 0 rgb(0 0 0 / 21%);
  }

  &__title,
  &__icon {
    color: currentColor;
  }

  &__title {
    margin: 0;
  }

  &__subtitle {
    color: var(--color-white-40);
    font-family: $font-primary;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__icon {
    font-size: 40px;
    line-height: 40px;
    color: var(--color-yellow);
  }

  &__attributes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    list-style: none;
    margin-top: 16px;
  }

  &__attribute {
    color: var(--color-white-40);
    font-family: $font-primary;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
  }
}
