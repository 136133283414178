
.Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 40px 0;
  background: var(--color-blue-10);

  &__image {
    display: block;
    width: 128px;
    height: 128px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    color: var(--color-white);
    margin-bottom: 8px;
    text-align: center;

    &:before {
      display: none;
    }
  }

  &__message {
    font-size: 20px;
    margin-bottom: 32px;
  }
}