@font-face {
  font-family: "AMF";
  src: url("../fonts/AMF.eot");
  src: url("../fonts/AMF.eot?#iefix") format("embedded-opentype"),
  url("../fonts/AMF.woff2") format("woff2"),
  url("../fonts/AMF.woff") format("woff"),
  url("../fonts/AMF.ttf") format("truetype"),
  url("../fonts/AMF.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "AMF";
    src: url("../fonts/AMF.svg#AMF") format("svg");
  }
}


$icons8-css-prefix: "icons8";
$icons8-font-name: "AMF";
$icons8-font-path: "../fonts";

@mixin icons8-font() {
  display: inline-block;
  font-family: $icons8-font-name;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
$icons8-var-home-page: "\f100";
$icons8-var-plus--: "\f101";
$icons8-var-news: "\f102";
$icons8-var-web: "\f103";
$icons8-var-file-explorer: "\f104";
$icons8-var-presentation-screen: "\f105";
$icons8-var-trophy: "\f106";
$icons8-var-leaderboard: "\f107";
$icons8-var-commercial: "\f108";
$icons8-var-soccer-player: "\f109";
$icons8-var-whistle: "\f10a";
$icons8-var-teams: "\f10b";
$icons8-var-red-card: "\f10c";
$icons8-var-stadium: "\f10d";
$icons8-var-settings: "\f10e";
$icons8-var-users: "\f10f";
$icons8-var-group-of-companies: "\f110";
$icons8-var-activity-history: "\f111";
$icons8-var-api: "\f112";
$icons8-var-user: "\f113";
$icons8-var-open-document: "\f114";
$icons8-var-logout: "\f115";
$icons8-var-notification-center: "\f116";
$icons8-var-gantt-chart: "\f117";
$icons8-var-soccer-ball: "\f118";
$icons8-var-globe-earth: "\f119";
$icons8-var-merge-git: "\f11a";
$icons8-var-tags: "\f11b";
$icons8-var-search: "\f11c";
$icons8-var-link: "\f11d";
$icons8-var-leadership: "\f11e";
$icons8-var-soccer-goal: "\f11f";
$icons8-var-xbox-a: "\f120";


.icons-home-page:before,
.icons-plus--:before,
.icons-news:before,
.icons-web:before,
.icons-file-explorer:before,
.icons-presentation-screen:before,
.icons-trophy:before,
.icons-leaderboard:before,
.icons-commercial:before,
.icons-soccer-player:before,
.icons-whistle:before,
.icons-teams:before,
.icons-red-card:before,
.icons-stadium:before,
.icons-settings:before,
.icons-users:before,
.icons-group-of-companies:before,
.icons-activity-history:before,
.icons-api:before,
.icons-user:before,
.icons-open-document:before,
.icons-logout:before,
.icons-notification-center:before,
.icons-gantt-chart:before,
.icons-soccer-ball:before,
.icons-globe-earth:before,
.icons-merge-git:before,
.icons-tags:before,
.icons-search:before,
.icons-link:before,
.icons-leadership:before,
.icons-soccer-goal:before,
.icons-xbox-a:before,
[data-icons8]:before {
  @include icons8-font;
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.icons-home-page:before {
  content: $icons8-var-home-page;
}
.icons-plus--:before {
  content: $icons8-var-plus--;
}
.icons-news:before {
  content: $icons8-var-news;
}
.icons-web:before {
  content: $icons8-var-web;
}
.icons-file-explorer:before {
  content: $icons8-var-file-explorer;
}
.icons-presentation-screen:before {
  content: $icons8-var-presentation-screen;
}
.icons-trophy:before {
  content: $icons8-var-trophy;
}
.icons-leaderboard:before {
  content: $icons8-var-leaderboard;
}
.icons-commercial:before {
  content: $icons8-var-commercial;
}
.icons-soccer-player:before {
  content: $icons8-var-soccer-player;
}
.icons-whistle:before {
  content: $icons8-var-whistle;
}
.icons-teams:before {
  content: $icons8-var-teams;
}
.icons-red-card:before {
  content: $icons8-var-red-card;
}
.icons-stadium:before {
  content: $icons8-var-stadium;
}
.icons-settings:before {
  content: $icons8-var-settings;
}
.icons-users:before {
  content: $icons8-var-users;
}
.icons-group-of-companies:before {
  content: $icons8-var-group-of-companies;
}
.icons-activity-history:before {
  content: $icons8-var-activity-history;
}
.icons-api:before {
  content: $icons8-var-api;
}
.icons-user:before {
  content: $icons8-var-user;
}
.icons-open-document:before {
  content: $icons8-var-open-document;
}
.icons-logout:before {
  content: $icons8-var-logout;
}
.icons-notification-center:before {
  content: $icons8-var-notification-center;
}
.icons-gantt-chart:before {
  content: $icons8-var-gantt-chart;
}
.icons-soccer-ball:before {
  content: $icons8-var-soccer-ball;
}
.icons-globe-earth:before {
  content: $icons8-var-globe-earth;
}
.icons-merge-git:before {
  content: $icons8-var-merge-git;
}
.icons-tags:before {
  content: $icons8-var-tags;
}
.icons-search:before {
  content: $icons8-var-search;
}
.icons-link:before {
  content: $icons8-var-link;
}
.icons-leadership:before {
  content: $icons8-var-leadership;
}
.icons-soccer-goal:before {
  content: $icons8-var-soccer-goal;
}
.icons-xbox-a:before {
  content: $icons8-var-xbox-a;
}
