

.MatchPlayOff {
  background: rgba(51, 51, 51, 1);

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(51, 51, 51, 1) 0%, rgba(51, 51, 51, 51) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 1) 100%);
  }
}
