

.Container {
  display: block;
  max-width: 1200px;
  padding: 0 32px;
  margin: auto;

  @media (max-width: $break-large) {
    max-width: 1024px;
  }

  @media (max-width: $break-small) {
    max-width: 100vw;
    padding: 0 24px;
  }

  @media (max-width: $break-xsmall) {
    padding: 0 16px;
  }

  &--center {
    text-align: center;
  }
}
