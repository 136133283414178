$font-primary: erbaum, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-native: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-red: rgb(234, 67, 53);
$color-red-10: rgb(140, 40, 32);

$color-green: rgb(131, 240, 163);
$color-green-10: rgb(99, 181, 123);

$color-yellow: #FFC81A;
$color-yellow-10: rgb(243, 191, 28);

$color-blue: rgb(11, 3, 202);
$color-blue-10: rgb(5, 11, 109);
$color-blue-20: rgb(0, 5, 85);
$color-blue-disabled: rgb(85, 81, 162);

$color-grey: rgb(234, 235, 242);
$color-grey-10: rgb(220, 221, 232);
$color-grey-20: rgb(181, 184, 203);

$color-text-primary: rgb(53, 57, 86);
$color-text-secondary: rgb(89, 91, 114);

$color-white-08: rgba(255, 255, 255, .08);
$color-white-16: rgba(255, 255, 255, .16);
$color-white-32: rgba(255, 255, 255, .32);
$color-white-40: rgba(255, 255, 255, .40);

$color-black-04: rgba(0, 0, 0, .04);
$color-black-08: rgba(0, 0, 0, .08);
$color-black-16: rgba(0, 0, 0, .16);
$color-black-32: rgba(0, 0, 0, .32);
$color-black-80: rgba(0, 0, 0, .80);

:root {
  --color-white: #{$color-white};
  --color-black: #{$color-black};

  --color-red: #{$color-red};
  --color-red-10: #{$color-red-10};

  --color-green: #{$color-green};
  --color-green-10: #{$color-green-10};

  --color-yellow: #{$color-yellow};
  --color-yellow-10: #{$color-yellow-10};

  --color-yellow-native: #{$color-yellow};
  --color-yellow-native-10: #{$color-yellow-10};

  --color-blue: #{$color-blue};
  --color-blue-10: #{$color-blue-10};
  --color-blue-20: #{$color-blue-20};
  --color-blue-disabled: #{$color-blue-disabled};

  --color-grey: #{$color-grey};
  --color-grey-10: #{$color-grey-10};
  --color-grey-20: #{$color-grey-20};

  --color-text-primary: #{$color-text-primary};
  --color-text-secondary: #{$color-text-secondary};

  --color-white-08: #{$color-white-08};
  --color-white-16: #{$color-white-16};
  --color-white-32: #{$color-white-32};
  --color-white-40: #{$color-white-40};

  --color-black-04: #{$color-black-04};
  --color-black-08: #{$color-black-08};
  --color-black-16: #{$color-black-16};
  --color-black-32: #{$color-black-32};
  --color-black-80: #{$color-black-80};

  --color-green-overlay-08: #{transparentize($color-green, .92)};

  --color-red-overlay-08: #{transparentize($color-red, .92)};
  --color-red-overlay-16: #{transparentize($color-red, .84)};

  --color-yellow-overlay-08: #{transparentize($color-yellow, .92)};
  --color-yellow-overlay-16: #{transparentize($color-yellow, .84)};
  --color-yellow-overlay-40: #{transparentize($color-yellow, .60)};

  --color-yellow-native-overlay-08: #{transparentize($color-yellow, .92)};
  --color-yellow-native-overlay-16: #{transparentize($color-yellow, .84)};
  --color-yellow-native-overlay-40: #{transparentize($color-yellow, .60)};

  --color-blue-overlay-04: #{transparentize($color-blue-20, .96)};
  --color-blue-overlay-08: #{transparentize($color-blue-20, .92)};
  --color-blue-overlay-16: #{transparentize($color-blue-20, .84)};
}

$animation-speed: 150ms;

$break-large: 1200px;
$break-normal: 860px;
$break-medium: 780px;
$break-small: 620px;
$break-xsmall: 560px;
$break-xxsmall: 420px;

$checkbox-dark-checked: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%2017l-5-5%201.41-1.42L10%2014.17l7.59-7.59L19%208l-9%209zm9-14H5c-1.11%200-2%20.89-2%202v14a2%202%200%20002%202h14a2%202%200%20002-2V5a2%202%200%2000-2-2z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
$checkbox-dark-unchecked: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.731%203.16h-14c-1.11%200-2%20.89-2%202v14a2%202%200%20002%202h14a2%202%200%20002-2v-14a2%202%200%2000-2-2zm0%202v14h-14v-14h14z%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
$checkbox-light-checked: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%2017l-5-5%201.41-1.42L10%2014.17l7.59-7.59L19%208l-9%209zm9-14H5c-1.11%200-2%20.89-2%202v14a2%202%200%20002%202h14a2%202%200%20002-2V5a2%202%200%2000-2-2z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E");
$checkbox-light-unchecked: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.731%203.16h-14c-1.11%200-2%20.89-2%202v14a2%202%200%20002%202h14a2%202%200%20002-2v-14a2%202%200%2000-2-2zm0%202v14h-14v-14h14z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E");

$radio-dark-unchecked: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%202C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3C%2Fsvg%3E");
$radio-dark-checked: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%207c-2.76%200-5%202.24-5%205s2.24%205%205%205%205-2.24%205-5-2.24-5-5-5zm0-5C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3C%2Fsvg%3E");
$radio-light-unchecked: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23FFFFFF%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%202C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3C%2Fsvg%3E");
$radio-light-checked: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23FFFFFF%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M12%207c-2.76%200-5%202.24-5%205s2.24%205%205%205%205-2.24%205-5-2.24-5-5-5zm0-5C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3C%2Fsvg%3E");
