

body {
  background: var(--color-white);

  &.withBlueBackground {
    background: var(--color-yellow);
  }

  &.withOverlay {
    overflow: hidden;
  }
}

.Layout {
  background: var(--color-white);
}
