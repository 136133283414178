

.SeasonListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  cursor: pointer;
  background: var(--color-yellow);
  color: var(--color-blue-20);
  text-align: center;
  aspect-ratio: 16 / 5;

  &__title,
  &__icon {
    color: currentColor;
  }

  &__title {
    margin: 0;
  }

  &__icon {
    font-size: 40px;
    line-height: 40px;
  }

  &__subtitle {
    color: var(--color-black-32);
    font-family: $font-primary;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 4px;
  }
}
