

.Filter {
  margin: 24px 0;

  @media (max-width: $break-small) {
    margin: 16px 0;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $break-large) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 8px;
    }

    @media (max-width: $break-normal) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__field {
    width: 100%;
    margin: 0;
  }
}
