

.GroundListItem {
  display: flex;
  align-items: center;
  background: var(--color-blue-overlay-04);
  border: 1px solid var(--color-blue-overlay-04);
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  transition: all ease-in $animation-speed;
  cursor: pointer;

  &:hover {
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
  }

  &__image {
    display: flex;
    width: 64px;
    height: 64px;
    min-width: 64px;
    background: var(--color-blue-overlay-04);
    color: var(--color-blue-overlay-16);
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 1;

    @media (max-width: $break-small) {
      width: 40px;
      height: 40px;
      min-width: 40px;
      font-size: 20px;
      border-radius: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__title {
    margin: 0;
    font-size: 14px;
  }

  &__subtitle {
    font-size: 14px;
    color: var(--color-text-secondary);
  }
}
