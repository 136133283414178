

.AdList {
  gap: 24px;
  width: 100%;
  margin: 24px 0;
  padding: 0;

  @media (max-width: 400px) {
    gap: 8px;
  }
}

.Ad {
  display: flex;
  padding: 0;
  border-radius: 0;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
