
.Navigation {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 12;
  margin-bottom: 64px;

  &--blue {
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
  }

  &--yellow {
    border-bottom: 3px solid var(--color-text-primary);
  }

  &--white {
    box-shadow: 5.4px 5.2px 15px 0 rgb(0 0 0 / 21%);
  }

  @media (max-width: $break-small) {
    margin-bottom: 40px;
  }

  &__language {
    height: 40px;
    background: var(--color-blue-20);
    color: var(--color-white);

    &Item {
      cursor: pointer;
      display: flex;
      gap: 8px;
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 12px;
      color: var(--color-blue-disabled);
      user-select: none;

      &--selected {
        color: var(--color-white);
      }

      &Flag {
        border-radius: 4px;
        overflow: hidden;
        object-fit: contain;
        width: 16px !important;
        height: auto !important;
        opacity: .4;
      }

      &--selected &Flag {
        opacity: 1;
      }
    }

    .Container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;
      height: 100%;
    }
  }

  &__main {
    height: 88px;

    &Container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      height: 100%;
    }
  }

  &--blue &__main {
    background: var(--color-blue-10);
    color: var(--color-white);
  }

  &--yellow &__main {
    background: var(--color-yellow);
    color: var(--color-blue-10);
  }

  &--white &__main {
    background: var(--color-white);
    color: var(--color-blue-10);
  }

  &__brand {
    display: block;
    width: 118px;
    height: 56px;
    min-width: 118px;
    user-select: none;

    @media (max-width: $break-small) {
      width: 84px;
      height: 40px;
      min-width: 84px;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    display: flex;
    gap: 24px;
    height: 100%;
    align-items: center;

    &Outer {
      height: 100%;
    }

    &Search {
      font-size: 14px !important;
      color: currentColor;
    }

    &Item {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 14px;
      font-family: $font-primary;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
        position: absolute;
        bottom: 14px;
        left: 50%;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background: url("../../../assets/icon-cross-green.svg") no-repeat center;
        background-size: 12px 12px;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
      }

      &:before {
        left: 50%;
        content: "";
        position: absolute;
        background: url("../../../assets/icon-menu-arrow.svg") no-repeat center;
        background-size: 22px 11px;
        width: 22px;
        height: 11px;
        visibility: hidden;
        transform: translateX(-50%) translateY(4px);
        opacity: 0;
        bottom: 0;
        transition: opacity ease-in-out $animation-speed, transform ease-in-out $animation-speed;
      }

      &--selected {
        color: var(--color-green);

        &:after {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &Submenu {
      height: 100%;
    }

    &Button {
      display: none;
      margin-right: -8px;

      svg {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
      }
    }
  }

  &--blue &__menuItem {
    color: var(--color-white);
  }

  &--yellow &__menuItem {
    color: var(--color-blue-10);

    &:after {
      background: url("../../../assets/icon-cross-blue.svg") no-repeat center;
    }

    &:before {
      background: url("../../../assets/icon-menu-arrow-blue.svg") no-repeat center;
    }
  }

  &--yellow &__menuButton svg * {
    fill: var(--color-blue-10);
  }

  &--white &__menuItem {
    color: var(--color-blue-10);

    &:after {
      background: url("../../../assets/icon-cross-yellow.svg") no-repeat center;
    }

    &:before {
      background: url("../../../assets/icon-menu-arrow-yellow.svg") no-repeat center;
    }
  }

  &--white &__menuButton svg * {
    fill: var(--color-blue-10);
  }

  &__submenu {
    display: flex;
    z-index: -1;
    position: fixed;
    top: 128px;
    left: 0;
    width: 100%;
    min-height: 80px;
    flex-wrap: wrap;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 5.4px 5.2px 15px 0 rgba(155, 155, 155, 0.21);
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 28px 24px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-8px);
    transition: opacity $animation-speed ease-in-out, transform $animation-speed ease-in-out;

    &Item {
      display: block;
      font-size: 14px;
      font-family: $font-primary;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      color: var(--color-blue-10);
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--selected {
        text-decoration: underline;
      }
    }
  }

  &--noLanguage &__submenu {
    top: 88px;
  }

  &--yellow &__submenu {
    background-color: var(--color-blue-10);

    &Item {
      color: var(--color-white);
    }
  }

  &--white &__submenu {
    background-color: var(--color-yellow);

    &Item {
      color: var(--color-blue-10);
    }
  }

  &__menuSubmenu:hover &__submenu {
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  &__menuSubmenu:hover &__menuItem:before {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%);
  }

  &--dropdown {

  }

  &--dropdown &__language {
    @media (max-width: $break-small) {
      height: 32px;
    }
  }

  &--dropdown &__main {
    @media (max-width: $break-small) {
      height: 64px;
    }
  }

  &--dropdown &__brand {

  }

  &--dropdown &__menu,
  &--dropdown &__signIn {
    display: none;
  }

  &--dropdown &__signIn {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px 24px;
    background: var(--color-blue-20);

    @media (max-width: $break-small) {
      padding: 0 16px 16px;
    }

    .Button {
      width: 100%;
      justify-content: center;
    }
  }

  &--dropdown.Navigation--white &__signIn {
    background: var(--color-white);
  }

  &--dropdown &__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 32px;
    gap: 8px;
    height: auto;
    max-width: 1200px;
    margin: auto;

    @media (max-width: $break-large) {
      max-width: 1024px;
    }

    &Outer {
      display: none;
      position: fixed;
      top: 128px;
      left: 0;
      right: 0;
      bottom: 0;
      padding-bottom: 92px;
      background: var(--color-blue-20);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: auto;
      will-change: tranform, opacity;

      @media (max-width: $break-small) {
        top: 96px;
        padding-bottom: 76px;
      }
    }

    @media (max-width: $break-small) {
      padding: 24px;
    }

    @media (max-width: $break-xsmall) {
      padding: 16px;
    }

    &Item {
      width: 100%;
      height: auto;
      line-height: 40px;
      text-align: left;

      &:after,
      &:before {
        display: none;
      }
    }

    &Submenu {
      width: 100%;
    }

    &Button {
      display: flex;
    }
  }

  &--dropdown.Navigation--noLanguage &__menuOuter {
    top: 88px;

    @media (max-width: $break-small) {
      top: 64px;
    }
  }

  &--dropdown.Navigation--yellow &__menuOuter {
    background: var(--color-yellow-10);
  }

  &--dropdown.Navigation--white &__menuOuter {
    border-top: 1px solid var(--color-grey-10 );
    background: var(--color-white);
  }

  &--dropdown &__submenu {
    position: static;
    opacity: 1;
    visibility: visible;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    gap: 8px;
    padding: 0 16px;
    margin: 8px 0;
    border-left: 1px solid var(--color-white-16);
    transform: none;

    &Item {
      color: var(--color-white);

      &--selected {
        text-decoration: none;
        color: var(--color-green);
      }
    }
  }

  &--dropdown.Navigation--yellow &__submenuItem {
    color: var(--color-blue-10);
  }

  &--dropdown.Navigation--white &__submenu {
    border-left-color: var(--color-grey-20);
  }

  &--dropdown.Navigation--white &__submenuItem {
    color: var(--color-blue-10);
  }

  &--dropdownOpened &__menuOuter {
    display: block;
    animation: NavigationMenuFadeIn ease-in-out $animation-speed forwards;
  }

  &--dropdownOpened &__signIn {
    display: block;
  }
}

@keyframes NavigationMenuFadeIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
