

body {
  background: var(--color-blue-20);

  &.withBlueBackground {
    background: var(--color-blue-10);
  }

  &.withOverlay {
    overflow: hidden;
  }
}

.Layout {
  background: var(--color-white);
  max-width: 100vw;

  &__content {
    min-height: 80vh;
  }
}
