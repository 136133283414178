
.Footer {
  background: var(--color-blue-20);
  border-top: 4px solid var(--color-green);
  color: var(--color-white);
  padding: 40px 0 64px;
  margin-top: 80px;

  h2,
  p,
  address {
    color: var(--color-white);
  }

  p,
  address {
    font-style: normal;
    line-height: 24px;
    margin: 0;
  }

  a {
    color: var(--color-green);
    font-weight: bold;
  }

  &__partners {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px;
    margin: 16px 0;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 16px;
    }

    &Item {
      display: flex;
      background: var(--color-white);
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      min-height: 80px;
      position: relative;

      &Image {
        position: absolute;
        top: 24px;
        left: 24px;
        right: 24px;
        bottom: 24px;

        @media (max-width: $break-medium) {
          top: 16px;
          left: 16px;
          right: 16px;
          bottom: 16px;
        }

        &--tall {
          top: 8px;
          bottom: 8px;
        }

        img {
          object-fit: contain;
        }
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px;

    @media (max-width: $break-large) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 16px;
    }
  }

  &__column {

  }

  &__brands {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    gap: 16px;

    &Item {
      position: relative;
      height: 100%;
      width: 45px;
    }
  }

  &__apps {
    margin-top: 24px;

    &Grid {
      display: flex;
      align-items: center;
      gap: 16px;

      a {
        display: block;
      }

      svg {
        display: block;
        width: 100%;
        max-width: 160px;
      }

      @media (max-width: $break-xxsmall) {
        gap: 8px;
      }
    }
  }
}
