

.AdvancedSearch {

  &__header {
    position: relative;
    z-index: 11;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -80px;
      left: 0;
      width: 100%;
      bottom: 32px;
      background: var(--color-yellow);

      @media (max-width: $break-xsmall) {
        display: none;
      }
    }
  }

  &__panel {
    position: relative;
    z-index: 1;
  }

  &__title {
    flex-grow: 1;
  }

  h1 {

    &:before {
      background: url("~@amf/shared/assets/icon-cross-blue.svg") no-repeat center;
      background-size: 24px 24px;
    }
  }

  &__field {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
  }

  &__icon {
    position: absolute;
    top: 22px;
    right: 22px;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-blue-10);
  }

  &__input {
    display: block;
    appearance: none;
    background: var(--color-white);
    border: none;
    outline: none;
    height: 64px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    padding: 0 64px 0 24px;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  &__resultList {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__resultItem {
    background: var(--color-black-04);
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 16px;
    color: var(--color-text-primary);
    transition: box-shadow ease-in-out 200ms;
    display: grid;
    grid-template-columns: 120px minmax(0, 1fr);
    gap: 16px;

    &:hover {
      border: 2px solid var(--color-blue-overlay-08);
    }

    &Image {
      background: var(--color-black-08);
      border-radius: 4px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      min-height: 80px;
      font-size: 40px;
      color: var(--color-black-16);
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;

      svg {
        width: 48px;
        height: 48px;
      }
    }

    &Content {

    }

    &Type {
      font-size: 12px;
      text-transform: uppercase;
      font-family: $font-primary;
    }

    &Title {
      margin: 0;
    }

    &Description {
      font-size: 14px;
      color: var(--color-text-secondary);
    }

    &Team {
      display: inline-block;
      padding: 4px 8px;
      background: var(--color-blue-overlay-16);
      color: var(--color-blue-20);
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 12px;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
}
